import React from 'react';
import { Divider } from 'antd';
import { CheckboxFilterList } from './CheckBoxFilterList';

const CheckboxFilterWrapper = ({ data: dataObj, handleOnCheck = () => null }) => {
    const [checkboxData, setCheckboxData] = React.useState({});

    React.useEffect(() => {
        handleOnCheck(checkboxData);
    }, [checkboxData, handleOnCheck]);

    const handleOnChange = (data) => {
        const { value, checked, name } = data;
        if (checked) setCheckboxData({ ...checkboxData, [name]: [...(checkboxData?.[name] || []), value] });
        else setCheckboxData({ ...checkboxData, [name]: checkboxData[name].filter((ele) => ele !== value) });
    };

    return (
        <div style={{ margin: "5px 0" }} className="d-flex flex-column">
            {Object.keys(dataObj).map((value, index) => (
                <div key={index}>
                    <CheckboxFilterList
                        title={value}
                        list={dataObj[value]}
                        onChange={({ target }) => handleOnChange(target)}
                    />
                    <Divider style={{ background: "#dfdfdf" }} />
                </div>
            ))}
        </div>
    );
};

export default CheckboxFilterWrapper;
