import React from 'react';
import { Button, Position, Classes } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { DeleteSvg, EditIcon, ProjSvg } from '../../../../ProjectSVG';
import { Link, useHistory } from 'react-router-dom';

export const ThemeActionPopover = ({ title = '', id = '', handleCopy = () => {}, handleDelete = () => {} }) => {
    const history = useHistory();
    return (
        <Popover2
            Position={Position.LEFT_BOTTOM}
            content={
                <div className="tabsPopover">
                    <p className="popoverTitle">{title}</p>
                    <hr />
                    <div className="popoverSubtitle">
                        <Link className="text-success cursor-pointer me-3" to={{ pathname: `/theme/${id}` }}>
                            <EditIcon />
                        </Link>
                        <span onClick={() => history.push(`/theme/${id}`)} className="popoverSubtitleText">
                            Edit Project
                        </span>
                    </div>
                    <div className="popoverSubtitle">
                        <ProjSvg />

                        <button
                            className={Classes.POPOVER_DISMISS}
                            style={{ border: 'WHITE', backgroundColor: 'WHITE', fontSize: '12px' }}
                            onClick={(e) => handleCopy(e)}>
                            Make a Copy of theme
                        </button>
                    </div>

                    <div className="popoverSubtitle">
                        <DeleteSvg />

                        <button
                            className={Classes.POPOVER_DISMISS}
                            style={{ border: 'WHITE', backgroundColor: 'WHITE', fontSize: '12px' }}
                            onClick={(e) => handleDelete(e)}>
                            Move to Trash
                        </button>
                    </div>
                </div>
            }>
            <Button icon="more" className="projectcardMore"></Button>
        </Popover2>
    );
};
