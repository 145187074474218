import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import Wizards from '../pages/Wizards';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
// import Project from '../pages/user/Project';
import Asset from '../pages/user/Asset';
import Library from '../pages/user/Library';
import { libraryIcon, myAssetICon, mycreationIcon } from '../ProjectSVG';
import Mylesson from '../pages/user/Mylesson';
import MyTemplate from '../pages/user/MyTemplate';
import CreateTemplate from '../pages/user/CreateTemplate';
import CreateLesson from '../pages/user/CreateLesson';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const Room = React.lazy(() => import('../pages/user/room'));
const Studio = React.lazy(() => import('../studio/studio'));
const Myproject = React.lazy(() => import('../pages/my-creation'));
const Project = React.lazy(() => import('../pages/project'));
// const Theme = React.lazy(() => import('../theme/theme'));
// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.user.type) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',

    exact: true,
    component: () => <Redirect to="/my-creation" />,
    route: PrivateRoute,
};

// User
// const homeRoutes = {
//     path: '/home',
//     name: 'Home',
//     icon: FeatherIcon.Home,
//     header: 'LifeWise',
//     component: Home,
//     roles: ['admin', 'user'],
//     route: PrivateRoute,
// };

const myCreationRoutes = {
    path: '/my-creation',
    name: 'My Projects',
    icon: mycreationIcon,
    // header: 'Navigation',
    component: Myproject,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};
const myAssetRoutes = {
    path: '/my-assets',
    name: 'My Assets',
    icon: myAssetICon,
    // header: 'Navigation',
    component: Asset,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};
const library = {
    path: '/creation',
    name: 'Library',
    icon: libraryIcon,
    // header: 'Navigation',
    component: Library,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};
const templates = {
    path: '/my-templates',
    name: 'My Templates',
    icon: libraryIcon,
    component: MyTemplate,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};
const lessons = {
    path: '/my-lessons',
    name: 'My Lessons',
    icon: libraryIcon,
    component: Mylesson,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

const createTemplates = {
    path: '/template',
    name: 'Create Template',
    icon: libraryIcon,
    component: CreateTemplate,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};
const createLessons = {
    path: '/lesson',
    name: 'Create Lesson',
    icon: libraryIcon,
    component: CreateLesson,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

// const share = {
//     path: '/share',
//     name: 'Shared with Me',
//     icon: shareIcon,
//     // header: 'Navigation',
//     component: Share,
//     roles: ['admin', 'user'],
//     route: PrivateRoute,
// };

// const roomRoutes = {
//     path: '/room',
//     name: 'room',
//     icon: FeatherIcon.Airplay,
//     // header: 'Navigation',
//     component: Meetings,
//     roles: ['admin'],
//     route: PrivateRoute
// };

// apps
//
// const calendarAppRoutes = {
//     path: '/apps/calendar',
//     name: 'Calendar',
//     header: 'Apps',
//     icon: FeatherIcon.Calendar,
//     component: CalendarApp,
//     route: PrivateRoute,
//     roles: ['Admin'],
// };
//
// const emailAppRoutes = {
//     path: '/apps/email',
//     name: 'Email',
//     icon: FeatherIcon.Inbox,
//     children: [
//         {
//             path: '/apps/email/inbox',
//             name: 'Inbox',
//             component: EmailInbox,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/apps/email/details',
//             name: 'Details',
//             component: EmailDetail,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/apps/email/compose',
//             name: 'Compose',
//             component: EmailCompose,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//     ]
// };
//
// const projectAppRoutes = {
//     path: '/apps/projects',
//     name: 'Projects',
//     icon: FeatherIcon.Briefcase,
//     children: [
//         {
//             path: '/apps/projects/list',
//             name: 'List',
//             component: ProjectList,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/apps/projects/detail',
//             name: 'Detail',
//             component: ProjectDetail,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//     ]
// };
//
// const taskAppRoutes = {
//     path: '/apps/tasks',
//     name: 'Tasks',
//     icon: FeatherIcon.Bookmark,
//     children: [
//         {
//             path: '/apps/tasks/list',
//             name: 'List',
//             component: TaskList,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/apps/tasks/board',
//             name: 'Board',
//             component: TaskBoard,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//     ],
// };
// homeRoutes,
const appRoutes = [myCreationRoutes, myAssetRoutes, library, templates, lessons, createTemplates, createLessons];
// calendarAppRoutes, emailAppRoutes, projectAppRoutes, taskAppRoutes];

//
// // pages
// const pagesRoutes = {
//     path: '/pages',
//     name: 'Pages',
//     header: 'Custom',
//     icon: FeatherIcon.FileText,
//     children: [
//         {
//             path: '/pages/starter',
//             name: 'Starter',
//             component: Starter,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/pages/profile',
//             name: 'Profile',
//             component: Profile,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/pages/activity',
//             name: 'Activity',
//             component: Activity,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/pages/invoice',
//             name: 'Invoice',
//             component: Invoice,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/pages/pricing',
//             name: 'Pricing',
//             component: Pricing,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/pages/error-404',
//             name: 'Error 404',
//             component: Error404,
//             route: Route
//         },
//         {
//             path: '/pages/error-500',
//             name: 'Error 500',
//             component: Error500,
//             route: Route
//         },
//     ]
// };
//
//
// // components
// const componentsRoutes = {
//     path: '/ui',
//     name: 'UI Elements',
//     header: 'Components',
//     icon: FeatherIcon.Package,
//     children: [
//         {
//             path: '/ui/bscomponents',
//             name: 'Bootstrap UI',
//             component: BSComponents,
//             route: PrivateRoute,
//             roles: ['Admin'],
//         },
//         {
//             path: '/ui/icons',
//             name: 'Icons',
//             children: [
//                 {
//                     path: '/ui/icons/feather',
//                     name: 'Feather Icons',
//                     component: FeatherIcons,
//                     route: PrivateRoute,
//                     roles: ['Admin'],
//                 },
//                 {
//                     path: '/ui/icons/unicons',
//                     name: 'Unicons Icons',
//                     component: UniconsIcons,
//                     route: PrivateRoute,
//                     roles: ['Admin'],
//                 },
//             ]
//         },
//         // {
//         //     path: '/ui/widgets',
//         //     name: 'Widgets',
//         //     component: Widgets,
//         //     route: PrivateRoute,
//         //     roles: ['Admin'],
//         // },
//
//     ]
// };
//
// // charts
// const chartRoutes = {
//     path: '/charts',
//     name: 'Charts',
//     component: Charts,
//     icon: FeatherIcon.PieChart,
//     roles: ['Admin'],
//     route: PrivateRoute
// }
//
//
// // forms
// const formsRoutes = {
//     path: '/forms',
//     name: 'Forms',
//     icon: FeatherIcon.FileText,
//     children: [
//         {
//             path: '/forms/basic',
//             name: 'Basic Elements',
//             component: BasicForms,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/advanced',
//             name: 'Advanced',
//             component: FormAdvanced,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/validation',
//             name: 'Validation',
//             component: FormValidation,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/wizard',
//             name: 'Wizard',
//             component: FormWizard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/editor',
//             name: 'Editor',
//             component: Editor,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/upload',
//             name: 'File Upload',
//             component: FileUpload,
//             route: PrivateRoute,
//         }
//     ]
// };
//
//
// const tableRoutes = {
//     path: '/tables',
//     name: 'Tables',
//     icon: FeatherIcon.Grid,
//     children: [
//         {
//             path: '/tables/basic',
//             name: 'Basic',
//             component: BasicTables,
//             route: PrivateRoute,
//         },
//         {
//             path: '/tables/advanced',
//             name: 'Advanced',
//             component: AdvancedTables,
//             route: PrivateRoute,
//         }]
// };

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/room/:cid',
            name: 'Room',
            component: Room,
            route: Route,
        },
        {
            path: '/room',
            name: 'Room',
            component: Room,
            route: Route,
        },
        {
            path: '/speaker/:cid',
            name: 'Room',
            component: Room,
            route: Route,
        },
        {
            path: '/studio/:cid',
            name: 'Studio',
            component: Studio,
            route: Route,
        },
        {
            path: '/theme/:cid',
            name: 'Theme',
            component: Studio,
            route: Route,
        },
        {
            path: '/studio',
            name: 'Studio',
            component: Studio,
            route: Route,
        },
        {
            path: '/wizards',
            name: 'wizards',
            component: Wizards,
            route: Route,
        },
        {
            path: '/Myproject',
            name: 'Myproject',
            component: Myproject,
            route: Route,
        },
        {
            path: '/Project',
            name: 'Project',
            component: Project,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    // dashboardRoutes,
    ...appRoutes,
    // pagesRoutes,
    // componentsRoutes,
    // chartRoutes,
    // formsRoutes,
    // tableRoutes,
    authRoutes,
];

// const authProtectedRoutes = [dashboardRoutes, ...appRoutes, pagesRoutes, componentsRoutes, chartRoutes, formsRoutes, tableRoutes];
const authProtectedRoutes = [...appRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
