import React from 'react';
import { EuiButton } from '@elastic/eui';

const NavigationButtonGroup = ({
    showNext = false,
    onNextStep = () => null,
    nextButtonLoading = false,
    nextButtomDisabled = false,
    showPrev = false,
    onPreviousStep = () => null,
    prevButtonLoading = false,
    prevButtomDisabled = false,
    showSubmit,
    onSubmit = () => null,
    submitButtonLoading = false,
    submitButtomDisabled = false
}) => {
    return (
        <div className="nav-buttons">
            <div className="prev-btn">
                {showPrev && (
                    <EuiButton
                        isLoading={prevButtonLoading}
                        className={prevButtomDisabled ? "disabled" : ""}
                        onClick={() => !prevButtomDisabled && !prevButtonLoading ? onPreviousStep() : null}
                    >
                        Previous
                    </EuiButton>
                )}
            </div>
            <div
                className="next-btn">
                {showNext ? (
                    <EuiButton
                        isLoading={nextButtonLoading}
                        className={nextButtomDisabled ? "disabled" : ""}
                        onClick={() => !nextButtonLoading && !nextButtomDisabled ? onNextStep() : null}
                    >
                        Next
                    </EuiButton>
                ) : showSubmit && (
                    <EuiButton
                        isLoading={submitButtonLoading}
                        className={submitButtomDisabled ? "disabled" : ""}
                        onClick={() => !submitButtonLoading && !submitButtomDisabled ? onSubmit() : null}
                    >
                        Submit
                    </EuiButton>
                )}
            </div>
        </div>
    )
}

export default NavigationButtonGroup
