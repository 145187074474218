import React from 'react';
import { Row, Col } from 'antd';
import { Crossbtn } from '../../../ProjectSVG';
import { Button } from '@blueprintjs/core';

export const ConfirmationForm = ({
    onClose = () => { },
    onConfirmation = () => { },
    onCancel = () => { }
}) => {
    return (
        <>
            <Row style={{ justifyContent: 'flex-end' }}>
                <button
                    minimal={false}
                    className="librarydiacrossbuttoncss"
                    onClick={e => onClose(e)}>
                    <Crossbtn />
                </button>
            </Row>
            <Row>
                <Col span={24} className="libarydiacolonecss">
                    Are you sure you want to delete this project?
                </Col>
                <Col span={24}>
                    <Button className="librarycanclecss" onClick={e => onCancel(e)}>
                        Cancel
                    </Button>
                    <Button className="librarycanclecs" onClick={() => onConfirmation}>
                        Yes
                    </Button>
                </Col>
            </Row>
        </>
    );
}