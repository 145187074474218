// @flow

import { all, call, fork, put, takeEvery, select, take } from 'redux-saga/effects';
import { fetchJSON } from '../../helpers/api';
import appConfig from '../../config';
import { startCall, remoteTracks, localTracks, room, newID } from '../../helpers/studioApi';
// import s1 from '../../studio/s1';
// import { getProjects } from '../../helpers/apiCalls';
import {
    // SHOW_BEGIN,
    // SHOW_END,
    // ELEMENT_UPDATE,
    // USER_JOIN,
    // ROOM_UPDATE,
    PROJECT_INIT,
    // INDEX_UPDATED,
    PROJECT_UPDATED,
    SELECTION_CHANGED,
    // SELECTION_UPDATED,
    ELEMENT_CHANGED,
    ELEMENT_UPDATED,
    ELEMENT_ADD,
    SLIDE_CHANGED,
    SLIDE_UPDATED,
    SLIDE_ADDED,
    SLIDE_SELECTION_CHANGED,
    // SLIDE_SELECTION_UPDATED,
    IMAGE_UPDATED,
    // SLIDE_IMAGE_UPDATE,
    SLIDE_IMAGE_UPDATED,
    SLIDE_INDEX_CHANGED,
    ELEMENT_INDEX_CHANGED,
    ELEMENT_DELETED,
    SLIDE_DELETED,
    ELEMENT_COPIED,
    SLIDE_COPIED,
} from './constants';

import {
    projectUpdated,
    selectionUpdated,
    slideSelectionUpdated,
    slideIndexUpdated,
    // elementIndexUpdated,
} from './actions';

import _ from 'lodash';
import { editProject, editTemplate, editTheme } from '../../helpers/apiCalls';

export const getProject = (state) => state.Studio.project;
export const getslide = (state) => state.Studio.selectedSlide;

function startCallForRoom(meetingID) {
    startCall(
        meetingID,
        (obj) => {
            console.log('Local Call back received : ', obj);
            // localChanged(obj);
        },
        (obj) => {
            console.log('Local Call back received : ', obj);
            // remoteChanged(obj);
        }
    );
}

function* getRoomInit(cid) {
    try {
        let prjctid = cid.payload.projectid;
        // console.log('ProjectId', cid.payload);
        let response;
        let project;
        if (cid.payload.projectName === 'template') {
            response = yield call(fetchJSON, appConfig.templates + '/' + prjctid);
            project = response.data?.attributes.template;
        } else if (cid.payload.projectName === 'theme') {
            response = yield call(fetchJSON, appConfig.theme + '/' + prjctid);
            project = response.data?.attributes.theme;
        } else {
            response = yield call(fetchJSON, appConfig.projects + '/' + prjctid);
            project = response.data?.attributes.project;
        }
        // console.log('SagaProject', project, project?.slides, response.data?.attributes.title);
        yield put(projectUpdated(project, project?.slides, response.data?.attributes.title, []));
        yield put(slideSelectionUpdated(project?.slides[0]));
    } catch (error) {
        console.error(error);
    }
}
function* getSlideIndexChanged(data) {
    try {
        if (data.payload?.slides?.length) {
            yield put(slideIndexUpdated(data.payload.slides));
        }
    } catch (error) {
        console.error(error);
    }
}

function* getElementIndexChanged(data) {
    try {
        const slide = yield select(getslide);
        let elements = data.payload.elements;
        slide.elements = elements;
        slide.elements.map((item, index) => (item.style['z-index'] = slide.elements.length - index));
        yield put(slideSelectionUpdated(slide));
    } catch (error) {
        console.error(error);
    }
}

function* slideSelectionChange(slideid) {
    try {
        const project = yield select(getProject);
        let slide = _.find(project.slides, { id: slideid.payload.selectedSlide });
        yield put(slideSelectionUpdated(slide));
    } catch (error) {
        console.error(error);
    }
}

function* getElementDeleted(del) {
    const deleteElementId = del.payload.show;
    const slide = yield select(getslide);
    let elem = _.findIndex(slide.elements, { id: deleteElementId });
    slide && slide.elements && slide.elements.splice(elem, 1);
}
function* getElementCopied(copy) {
    const copyElementId = copy.payload.show;
    const slide = yield select(getslide);
    let elem = _.findIndex(slide.elements, { id: copyElementId });
    let CopiedElement = JSON.parse(JSON.stringify(slide.elements[elem]));
    CopiedElement.id = newID('element');
    //making default Top Left Position
    CopiedElement.position.top = 0;
    CopiedElement.position.left = 0;

    slide.elements.push(CopiedElement);
    //re Calculating The Index
    slide.elements.map((item, index) => (item.style['z-index'] = slide.elements.length - index));
}
function* getSlideDeleted(del) {
    const deleteSlideId = del.payload.show;
    const project = yield select(getProject);
    let slide = _.findIndex(project.slides, { id: deleteSlideId.id });
    project.slides.splice(slide, 1);
    yield put(slideIndexUpdated(project.slides));
    if (!!project.slides.length) yield put(slideSelectionUpdated(project.slides[slide - 1]));
}
// function* getSlideCopied(copy) {
//     const copySlideId = copy.payload.show;
//     const project = yield select(getProject);
//     let slide = _.findIndex(project.slides, { id: copySlideId.id });
//     let CopiedSlide = JSON.parse(JSON.stringify(project.slides[slide]));
//     CopiedSlide.id = newID('slide');
//     project.slides[CopiedSlide.index] = CopiedSlide;
//     // project.slides.push(CopiedSlide);
//     project.slides.splice(CopiedSlide.index, 0, CopiedSlide);
//     yield put(slideIndexUpdated(project.slides));
// }
function* getSlideCopied(copy) {
    const copySlideId = copy.payload.show;
    const project = yield select(getProject);
    // console.log('RED Copied ELEMENT', project.slides);
    let slide = _.findIndex(project.slides, { id: copySlideId.id });
    let CopiedSlide = JSON.parse(JSON.stringify(project.slides[slide]));
    // console.log('RED Result Element Copied', CopiedSlide);
    CopiedSlide.id = newID('slide');
    //  project.slides[CopiedSlide.index] = CopiedSlide;
    project.slides.splice(CopiedSlide.index, 0, CopiedSlide);
    yield put(slideIndexUpdated(project.slides));
}
function* getElementChanged(chng) {
    try {
        let { element, value, propName, actives } = chng.payload;
        const slide = yield select(getslide);
        let elem = _.findIndex(slide.elements, { id: element.id });
        if (propName === 'name') {
            slide.elements[elem].name = value;
        } else if (propName === 'z-index') {
            switch (value) {
                case 'bringForward':
                    let remainingBringForward = slide.elements.filter((item) => item.id !== element.id);
                    slide.elements = [...remainingBringForward, element];
                    break;
                case 'sendBackward':
                    let remainingSendBackward = slide.elements.filter((item) => item.id !== element.id);
                    slide.elements = [element, ...remainingSendBackward];
                    break;
                case 'bringFront':
                    let findBringFront = slide.elements.findIndex((item) => item.id === element.id);
                    let slideLengthFront = slide.elements.length;
                    if (findBringFront + 1 !== slideLengthFront) {
                        let firstSwap = slide.elements[findBringFront];
                        let secondSwap = slide.elements[findBringFront + 1];
                        slide.elements[findBringFront] = secondSwap;
                        slide.elements[findBringFront + 1] = firstSwap;
                    }
                    break;
                case 'sendBack':
                    let findSendBack = slide.elements.findIndex((item) => item.id === element.id);
                    if (findBringFront !== 0) {
                        let firstSwap = slide.elements[findSendBack];
                        let secondSwap = slide.elements[findSendBack - 1];
                        slide.elements[findSendBack] = secondSwap;
                        slide.elements[findSendBack - 1] = firstSwap;
                    }
                    break;
                default:
                    break;
            }
        } else if (
            propName === 'top' ||
            propName === 'left' ||
            propName === 'right' ||
            propName === 'bottom' ||
            propName === 'width' ||
            propName === 'height'
        ) {
            slide.elements[elem].position[propName] = value;
        } else if (propName === 'imageCrop') {
            // slide.elements[elem].position['width'] = value.width;
            // slide.elements[elem].position['height'] = value.height;
            slide.elements[elem].content.src = value;
        } else if (propName === 'shapewidth' || propName === 'shapeheight') {
            let shapesObject = JSON.parse(slide.elements[elem].content.icon);
        } else if (propName === 'embed') {
            slide.elements[elem].content[propName] = value;
        } else if (propName === 'textChange') {
            slide.elements[elem].content.text = value;
        } else if (propName === 'question') {
            slide.elements[elem].question = value;
        }
        // else if(propName === 'crop'){

        // }
        // else if (propName === 'subType') {
        //     slide.elements[elem].subType = value;
        // }
        else if (propName === 'disable') {
            slide.elements[elem].disable = value;
        } else if (value && propName === 'alphabet') {
            slide.elements[elem].content.text = element.content.text.toUpperCase();
        } else if (!value && propName === 'alphabet') {
            const lowerCase = element.content.text.toLowerCase();
            const arr = lowerCase.split(' ');
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(' ');
            slide.elements[elem].content.text = str2;
        } else if (
            propName === 'backgroundColor' ||
            propName === 'opacity' ||
            propName === 'color' ||
            propName === 'font-size' ||
            propName === 'font-family' ||
            propName === 'font-weight' ||
            propName === 'border-color' ||
            propName === 'border-radius' ||
            propName === 'border-style' ||
            propName === 'border-width' ||
            propName === 'text-align' ||
            propName === 'text-decoration' ||
            propName === 'font-style' ||
            propName === 'display' ||
            propName === 'transformRotate' ||
            propName === 'transformSkew' ||
            propName === 'vertical-align' ||
            propName === 'fill' ||
            propName === 'stroke' ||
            propName === 'strokeWidth'
        ) {
            slide.elements[elem].style[propName] = value;
        } else if (propName === 'text') {
            slide.elements[elem].content[propName] = value;
        } else if (propName === 'showCall') {
            slide.elements[elem].event[0].call = value;
        } else if (propName === 'showParam') {
            let filteredValue = value.filter((item) => item.length !== 0);
            slide.elements[elem].event[0].param = value;
        } else if (propName === 'showType') {
            slide.elements[elem].event[0].type = value;
        } else if (propName === 'switchCall') {
            slide.elements[elem].event[1].call = value;
        } else if (propName === 'switchParam') {
            slide.elements[elem].event[1].param = value;
        } else if (propName === 'switchType') {
            slide.elements[elem].event[1].type = value;
        } else if (propName === 'hideCall') {
            slide.elements[elem].event[2].call = value;
        } else if (propName === 'hideType') {
            slide.elements[elem].event[2].type = value;
        } else if (propName === 'hideParam') {
            let filteredValue = value.filter((item) => item.length !== 0);

            slide.elements[elem].event[2].param = value;
        } else if (propName === 'linkParam') {
            slide.elements[elem].event[4].param = value;
        } else if (propName === 'linkType') {
            slide.elements[elem].event[4].type = value;
        } else if (propName === 'linkCall') {
            slide.elements[elem].event[4].call = value;
        } else if (propName === 'addclassCall') {
            slide.elements[elem].event[3].call = value;
        } else if (propName === 'addclassParam') {
            slide.elements[elem].event[3].param = { ids: [value.event], class: value.classes };
        } else if (propName === 'addclassType') {
            slide.elements[elem].event[3].type = value;
        } else if (propName === 'styleClasses') {
            slide.elements[elem][propName] = [...value];
        }
        yield put(slideSelectionUpdated(slide));
    } catch (error) {
        console.error(error);
    }
}
function* getslideChanged(chng) {
    try {
        let { element, value, propName } = chng.payload;
        const slide = yield select(getslide);
        let sld = _.findIndex(slide, { id: slide.id });
        if (propName === 'Name') {
            slide.Meta.Name = value;
        } else if (propName === 'background-color') {
            slide.style[propName] = value;
        }

        if (propName !== 'Name') {
            slide.style['background-image'] = '';
        }
        yield put(slideSelectionUpdated(slide));
    } catch (error) {
        console.error(error);
    }
}
function* getImageUpdated(chng) {
    try {
        let { image, dime, value, element } = chng.payload.element;
        const slide = yield select(getslide);
        let elem = _.findIndex(slide.elements, { id: element.id });
        slide.elements[elem].content.src = value;
        slide.elements[elem].position['width'] = `${dime.width}`;
        slide.elements[elem].position['height'] = `${dime.height}`;
        yield put(slideSelectionUpdated(slide));
    } catch (error) {
        console.error(error);
    }
}
function* getSlideImageUpdated(chng) {
    // console.log("stylecss",chng)
    try {
        let { element, image } = chng.payload;
        const slide = yield select(getslide);
        let sld = _.findIndex(slide, { id: slide.id });
        // console.log('Getting index of image element', elem);
        // let currentImage = ;
        // slide.style['background-image'] = image ;
        // slide.style['background-image'] = image;
        if (element.image) {
            slide.style['background-image'] = `url("${element.image}")`;
            slide.style['background-color'] = '';
        } else {
            delete slide.style['background-image'];
            delete slide.style['background-color'];
        }
        yield put(slideSelectionUpdated(slide));
    } catch (error) {
        console.error(error);
    }
}
function* getElementAdd(add) {
    try {
        let { type } = add.payload;
        let { value } = type;
        const slide = yield select(getslide);

        if (type === 'button') {
            let elemTempButton = JSON.parse(JSON.stringify(appConfig.elemButton));
            elemTempButton.id = newID('element');
            elemTempButton.type = value;
            elemTempButton.position['width'] = `${type.dimension.width}`;
            elemTempButton.position['height'] = `${type.dimension.height}`;
            slide.elements.push(elemTempButton);
        } else if (type === 'text') {
            let elemTempText = JSON.parse(JSON.stringify(appConfig.elemText));
            elemTempText.id = newID('element');
            slide.elements.push(elemTempText);
        } else if (type.type === 'texts') {
            appConfig.elemText.content.text = type.code;
            appConfig.elemText.subType = type.subType;
            appConfig.elemText.position.width = 60;
            appConfig.elemText.style['font-family'] = 'Open Sans';
            switch (type.subType) {
                case 'text box':
                    appConfig.elemText.style['font-size'] = 26;
                    appConfig.elemText.position.height = 18;

                    break;
                case 'heading':
                    appConfig.elemText.style['font-size'] = 76;
                    appConfig.elemText.position.height = 98;

                    break;
                case 'title':
                    appConfig.elemText.style['font-size'] = 65;
                    appConfig.elemText.position.height = 55;

                    break;

                case 'subTitle':
                    appConfig.elemText.style['font-size'] = 35;
                    appConfig.elemText.position.height = 18;

                    break;

                case 'body text':
                    appConfig.elemText.style['font-size'] = 31;
                    appConfig.elemText.position.height = 17;

                    break;
                case 'bulleted text':
                    appConfig.elemText.style['font-size'] = 31;
                    appConfig.elemText.position.height = 16;

                    break;
                case 'numbered text':
                    appConfig.elemText.style['font-size'] = 31;
                    appConfig.elemText.position.height = 16;

                    break;
                case 'foodnote':
                    appConfig.elemText.style['font-size'] = 15;
                    appConfig.elemText.position.height = 15;

                    break;
                default:
                    break;
            }
            if (type.fontFamily) appConfig.elemText.style['font-family'] = type.fontFamily;

            let elemTempText = JSON.parse(JSON.stringify(appConfig.elemText));

            elemTempText.id = newID('element');
            slide.elements.push(elemTempText);
        } else if (type.type && type.type === 'embed') {
            let elemTempVideo = JSON.parse(JSON.stringify(appConfig.elemVideo));
            elemTempVideo.id = newID('element');
            elemTempVideo.attributes = value.attributes;
            elemTempVideo.content.embed = value.code;
            slide.elements.push(elemTempVideo);
        } else if (type.type && type.type === 'svg') {
            let elemTempSvg = JSON.parse(JSON.stringify(appConfig.elemSvg));
            elemTempSvg.id = newID('element');
            elemTempSvg.attributes = value.attributes;
            elemTempSvg.childrens = value.childrens;
            elemTempSvg.position['width'] = '10';
            elemTempSvg.position['height'] = '10';
            slide.elements.push(elemTempSvg);
        } else {
            // appConfig.elemImg.content.src = type;
            let elemTempImg = JSON.parse(JSON.stringify(appConfig.elemImg));
            elemTempImg.content.src = value;
            elemTempImg.position['width'] = `${type.dimension.width}`;
            elemTempImg.position['height'] = `${type.dimension.height}`;
            elemTempImg.id = newID('element');
            slide.elements.push(elemTempImg);
        }
        yield put(slideSelectionUpdated(slide));
    } catch (error) {
        console.error(error);
    }
}
function* getSlideAdded(add) {
    try {
        // let { index } = add.payload;
        const slide = yield select(getProject);
        // const sldid = newID('slide');
        if (add.type === 'SLIDE_ADDED') {
            let sldTemp = JSON.parse(JSON.stringify(appConfig.slideImage));
            sldTemp.id = newID('slide');
            slide.slides.push(sldTemp);
            yield put(projectUpdated(slide));
        }
    } catch (error) {
        console.error(error);
    }
}
function* getSelectionChanged(chng) {
    try {
        yield put(selectionUpdated(chng.payload.item, chng.payload.show));
    } catch (error) {
        console.error(error);
    }
}

function* getRemoteChanged() {
    try {
    } catch (error) {
        console.error(error);
    }
}

function* getLocalChanged() {
    try {
    } catch (error) {
        console.error(error);
    }
}

function* getTrackChanged(tracks) {
    try {
        // console.log("Local is Updated : ", tracks);
        // yield put(trackUpdated(tracks.payload.tracks, room));
    } catch (error) {
        console.error(error);
    }
}

function* getFeedsChanged(inp) {
    try {
        let feed = makeFeeds();
        // yield put(feedsUpdated(feed, room));
    } catch (error) {
        console.error(error);
    }
}

function makeFeeds() {
    let feeds = [];
    let localObj = {
        type: 'localTracks',
        index: 0,
        id: 'localTracks',
        tracks: [],
    };

    if (localTracks.length) {
        localTracks.map((trk) => {
            let ltobj = {
                type: trk.getType(),
            };
            localObj.tracks.push(ltobj);
        });
    }
    feeds.push(localObj);
    let patici = room.participants;

    if (patici && Object.keys(patici).length) {
        Object.keys(patici).map((trackID, index) => {
            let rmObj = {
                type: 'remoteTracks',
                index: index,
                id: trackID,
                tracks: [],
            };
            patici[trackID]._tracks.map((track, idx) => {
                let trackObj = {
                    type: track.getType(),
                };
                rmObj.tracks.push(trackObj);
            });
            feeds.push(rmObj);
        });
    }

    return feeds;
}

export function* watchRoomInit() {
    yield takeEvery(PROJECT_INIT, getRoomInit);
}
export function* slideIndexChanged() {
    yield takeEvery(SLIDE_INDEX_CHANGED, getSlideIndexChanged);
}
export function* elementIndexChanged() {
    yield takeEvery(ELEMENT_INDEX_CHANGED, getElementIndexChanged);
}

export function* watchSlideSelectionChanged() {
    yield takeEvery(SLIDE_SELECTION_CHANGED, slideSelectionChange);
}

export function* watchElementDeleted() {
    yield takeEvery(ELEMENT_DELETED, getElementDeleted);
}
export function* watchElementCopied() {
    yield takeEvery(ELEMENT_COPIED, getElementCopied);
}
export function* watchSlideCopied() {
    yield takeEvery(SLIDE_COPIED, getSlideCopied);
}
export function* watchSlideDeleted() {
    yield takeEvery(SLIDE_DELETED, getSlideDeleted);
}
export function* watchElementChanged() {
    yield takeEvery(ELEMENT_UPDATED, getElementChanged);
}
export function* watchSlideChanged() {
    yield takeEvery(SLIDE_UPDATED, getslideChanged);
}
export function* watchImageUpdated() {
    yield takeEvery(IMAGE_UPDATED, getImageUpdated);
}
export function* watchSlideImageUpdated() {
    yield takeEvery(SLIDE_IMAGE_UPDATED, getSlideImageUpdated);
}
export function* watchElementAdd() {
    yield takeEvery(ELEMENT_ADD, getElementAdd);
}
// export function* watchElementChanged() {
//     yield takeEvery(ELEMENT_CHANGED, getElementChanged);
// }
export function* watchSlideAddedWithImage() {
    yield takeEvery(SLIDE_ADDED, getSlideAdded);
}
export function* watchSelectionChanged() {
    yield takeEvery(SELECTION_CHANGED, getSelectionChanged);
}

export function* watchRemoteChanged() {
    yield takeEvery(ELEMENT_CHANGED, getRemoteChanged);
}

export function* watchLocalChanged() {
    yield takeEvery(ELEMENT_UPDATED, getLocalChanged);
}

export function* watchTrackChanged() {
    yield takeEvery(SLIDE_CHANGED, getTrackChanged);
}

export function* watchFeedsChanged() {
    yield takeEvery(SLIDE_UPDATED, getFeedsChanged);
}
function* watchAndLog() {
    while (true) {
        const action = yield take('*');
        const state = yield select();

        if (action.type.includes('ELEMENT')) {
            try {
                const { project, projectName, projectid } = state.Studio;
                if (projectName === 'template') {
                    let data = { data: { template: project } };
                    let resp = yield call(editTemplate, projectid, data);
                } else if (projectName === 'theme') {
                    let data = { data: { theme: project } };
                    let resp = yield call(editTheme, projectid, data);
                } else {
                    let data = { data: { project } };
                    let resp = yield call(editProject, projectid, data);
                }
            } catch (err) {
                console.log('SAGA-AUTO-SAVE FAIL', err);
            }
        }
    }
}

function* studioSaga() {
    yield all([
        fork(watchRoomInit),
        fork(elementIndexChanged),
        fork(slideIndexChanged),
        fork(watchSlideSelectionChanged),
        fork(watchSlideAddedWithImage),
        fork(watchImageUpdated),
        fork(watchSlideImageUpdated),
        fork(watchElementChanged),
        fork(watchElementDeleted),
        fork(watchSlideDeleted),
        fork(watchElementCopied),
        fork(watchSlideCopied),
        fork(watchSlideChanged),
        fork(watchElementAdd),
        fork(watchRemoteChanged),
        fork(watchLocalChanged),
        fork(watchSelectionChanged),
        fork(watchTrackChanged),
        fork(watchFeedsChanged),
        fork(watchAndLog),
    ]);
}

export default studioSaga;
