import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { Beautify, Eye } from '../../../assets/svgs';
import Wizards from '../../../pages/Wizards';
import { LessonTemplatePreview } from '../../PreviewModal';

export const TemplateCardHoverSide = ({ images, id, tags = [] }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showWizardModal, setShowWizardModal] = useState(false);

    const handleOk = () => setIsModalOpen(false);
    const handleCancel = () => setIsModalOpen(false);
    const handleUseTemplateBtn = () => setShowWizardModal(true);

    return (
        <React.Fragment>
            <div className="hover-section d-flex-center flex-column br-sm gap-4">
                <Button
                    icon={<Eye />}
                    className={'d-flex align-items-center gap-4 primary-button w-80'}
                    style={{ fontSize: 12 }}
                    onClick={() => setIsModalOpen(true)}>
                    Preview this template
                </Button>
                <Button
                    icon={<Beautify />}
                    className={'d-flex align-items-center gap-4 secondary-button w-80'}
                    style={{ fontSize: 12 }}
                    onClick={handleUseTemplateBtn}>
                    Use this template
                </Button>
            </div>
            {modalSection({ images, id, isModalOpen, handleCancel, handleOk, tags })}
            {showWizardModal && <Wizards id={id} visible={showWizardModal} setVisible={setShowWizardModal} />}
        </React.Fragment>
    );
};

const modalSection = ({ images, id, isModalOpen, handleCancel, handleOk, tags = [] }) => {
    return (
        <Modal
            title={null}
            width={1098}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            className="primary-modal">
            <LessonTemplatePreview images={images} id={id} tags={tags} />
        </Modal>
    );
};
