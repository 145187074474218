import React from 'react';
import { AiOutlineHeart } from 'react-icons/ai';
import { Button, Divider, Typography } from 'antd';
import { Beautify } from '../../assets/svgs';
import Wizards from '../../pages/Wizards';
import { useHistory } from 'react-router-dom';
const { Title, Paragraph } = Typography;

export const DescriptionSection = ({ id, tags = [] }) => {
    const [showWizardModal, setShowWizardModal] = React.useState(false);
    const history = useHistory();
    return (
        <>
            <Title level={3} className="mb-3 fs-xxl fw-700">
                Explore Lesson Template
            </Title>
            <div className="d-flex justify-content-between">
                <div className="tag-section">{createTagList(tags)}</div>
                <div className="logo-section">
                    <AiOutlineHeart />
                </div>
            </div>
            <Divider className="mt-1 mb-4" />
            <Paragraph className="mb-2 fw-400">
                Explore includes multiple opportunities to explore the learning objectives through discussion and
                interactive class activities. Recommended for topics that inspire creativity, spark debate or for any
                classrooms who thrive on collaboration!
            </Paragraph>
            <Paragraph className="bg-color-2 br-sm" style={{ padding: '18px 24px' }}>
                This template is powered by our lesson builder wizard which helps you create amazing lessons in minutes.
                Just add your content, we’ll take care of the rest!
            </Paragraph>
            <div className="d-flex justify-content-between">
                <Button
                    className={'d-flex align-items-center gap-4 primary-button'}
                    style={{ fontSize: 12 }}
                    onClick={() => history.push('/lesson?id=' + id)}>
                    Edit this lesson
                </Button>
                <Button
                    icon={<Beautify />}
                    className={'d-flex align-items-center gap-4 secondary-button'}
                    style={{ fontSize: 12 }}
                    onClick={() => setShowWizardModal(true)}>
                    Use this template
                </Button>
            </div>
            {showWizardModal && <Wizards id={id} visible={showWizardModal} setVisible={setShowWizardModal} />}
        </>
    );
};

const createTagList = (tags = []) => {
    return tags.map(({ attributes, id }) => (
        <Button type="link" key={id} className="primary-color px-1">
            {attributes.tagName}
        </Button>
    ));
};
