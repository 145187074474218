import { H2 } from '@blueprintjs/core';
import React from 'react';

export const CardListContainer = ({ children, className = '', title }) => {
    return (
        <div>
            {title ? <H2 className="bannerProjectSubtitle ">{title}</H2> : null}
            <div id="projects" className={`librarycarddatacss ${className}`}>
                {children}
            </div>
        </div>
    );
};
