import React from 'react';
import dummyImage from '../assets/images/b1.png';
import { Button } from 'antd';
import { Beautify, Eye } from '../assets/svgs';

const ThemeView = ({ title, description, image, onPreview, isSelected, onClick }) => {
    return (
        <div className="template-image">
            <div className={`${isSelected ? 'selected' : null} image`} onClick={onClick}>
                <div className="image-preview">
                    <Button
                        icon={<Eye />}
                        onClick={onPreview}
                        className={'d-flex w-75 align-items-center gap-4 primary-button'}
                        style={{ fontSize: 12 }}>
                        Preview this Theme
                    </Button>
                    {!isSelected && (
                        <Button
                            icon={<Beautify />}
                            className={'d-flex w-75 align-items-center gap-4 secondary-button'}
                            style={{ fontSize: 12 }}>
                            Use this template
                        </Button>
                    )}
                </div>
                <img className={'main-image'} src={image ? image : dummyImage} alt="dummyImage" />
            </div>
            <div className="template-desc">
                <div className="title">{title}</div>
                <div className="description">{description}</div>
            </div>
        </div>
    );
};

export default ThemeView;
