import React from "react";
import { Input } from "antd";

const FormInput = ({ label, value, onChange }) => {
    return (
        <div className={"form-input"}>
            <label className={"labels"}>{label}</label>
            <Input
                className={"input"}
                value={value}
                onChange={({ target }) =>
                    onChange(target.value[0] === " " ? target.value.substring(1) : target.value.replace(/  +/g, " "))
                }
            />
        </div>
    );
};

export default FormInput;
