import React from 'react';
import { Button, Position, Classes } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { DeleteSvg, EditIcon, ProjSvg } from '../../src/ProjectSVG';
import { Link, useHistory } from 'react-router-dom';

const PopOverContent = ({ title = '', id = '' , handleCopy = () => { },
handleDelete = () => { },}) => {
    const history=useHistory()
    return (
        <Popover2
            Position={Position.LEFT_BOTTOM}
            content={
                <div className="card_pop">
                   
                    <div className="popoverSubtitle">
                        <Link
                            className="text-success cursor-pointer me-3"
                            to={{ pathname: `/studio/${id}?type=template` }}>
                            <EditIcon />
                        </Link>
                        <button
                            className={Classes.POPOVER_DISMISS}
                            style={{
                                border: 'white',
                                backgroundColor: 'white',
                                fontFamily: 'Lexend',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                marginLeft: '14px',
                            }}
                            // className="btn_pop"
                            onClick={() => history.push(`/studio/${id}?type=template`)}
                        >
                            Continue Editing
                        </button>
                    </div>
                    <div className="popoverSubtitle">
                        <ProjSvg />

                        <button
                            className={Classes.POPOVER_DISMISS}
                            style={{
                                border: 'white',
                                backgroundColor: 'white',
                                fontFamily: 'Lexend',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                marginLeft: '14px',
                            }} 
                            onClick={e => handleCopy(e)}
                        >
                            Make a Copy
                        </button>
                    </div>

                    <div className="popoverSubtitle">
                        <DeleteSvg />

                        <button
                            className={Classes.POPOVER_DISMISS}
                            style={{
                                border: 'white',
                                backgroundColor: 'white',
                                fontFamily: 'Lexend',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                marginLeft: '14px',
                            }}
                             onClick={e => handleDelete(e)}
                        >
                            Delete this Creation
                        </button>
                    </div>
                </div>
            }>
            <Button icon="more" className="projectcardMore"></Button>
        </Popover2>
    );
};
export default PopOverContent;
