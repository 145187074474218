import React from 'react';
import { Col, Row } from 'antd';
import { CarouselSection } from './CarouselSection';
import { DescriptionSection } from './DescriptionSection';
import { LogoIcon } from '../../assets/svgs';

export const LessonTemplatePreview = ({ images, id, tags, themeViewModal = false, title, description }) => {
    return (
        <div>
            {themeViewModal && (
                <Row style={{ marginBottom: 24, padding: 12  }}>
                    <Col>
                        <div style={{ fontSize: 24, fontWeight: 500, marginBottom: 6 }} className="title">
                            {title}
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 300 }} className="description">
                            {description}
                        </div>
                    </Col>
                </Row>
            )}
            <Row className='theme-preview-modal'>
                <Col md={24} lg={!themeViewModal ? 12 : 24}>
                    <CarouselSection images={images} themeViewModal={themeViewModal} />
                </Col>
                {!themeViewModal && (
                    <Col md={24} lg={12}>
                        <DescriptionSection id={id} tags={tags} />
                    </Col>
                )}
            </Row>
            <div style={{ position: "absolute", bottom: 20, right: 20 }}>
                <LogoIcon />
            </div>
        </div>
    );
};
