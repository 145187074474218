// element types in canvas frame component
export const ELEMENT_Type = {
    DIV: 'div',
    SVG: 'svg',
    IMG: 'img',
    TEXT: 'text',
    RND: 'rnd',
    INDIV: 'indiv',
    POS: 'pos',
    SHAPES: 'shapes',
    ALL: 'all'
}