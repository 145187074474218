import http from './http.service';

export const fetchData = async (url) => {
    try {
        const { data } = await http.get(url);
        return { response: data };
    } catch (error) {
        return { error };
    }
};

export const createData = async (url, values) => {
    try {
        const { data } = await http.post(url, { data: values });
        return { response: data };
    } catch (error) {
        return { error };
    }
};

export const updateData = async (url, values) => {
    try {
        const { data } = await http.put(url, { data: values });
        return { response: data };
    } catch (error) {
        return { error };
    }
};

export const getTemplates = async ({ query = '' }) => {
    try {
        const {data} = await http
            .get(`https://apilw.html5.run/api/templates?${query}`)
            return { response: data };
    } catch (error) {
        return { error };
        // throw new Error(err)
    }
};
