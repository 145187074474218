import React from 'react';
import { Checkbox } from 'antd';

export const CheckboxFilterList = ({ title, list, ...rest }) => {
    return (
        <React.Fragment>
            <h4 className="primary-color fw-700 mb-3" style={{ fontSize: 16 }}>
                {title}
            </h4>
            <div className="d-flex flex-column filter-checkbox" style={{ gap: 12 }}>
                {list.map((filterName) => (
                    <Checkbox
                        className="grey-color"
                        style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
                        key={filterName}
                        value={filterName}
                        name={title}
                        {...rest}
                    >
                        {filterName}
                    </Checkbox>
                ))}
            </div>
        </React.Fragment>
    );
};
