import React from 'react';
import { Button, Card, Elevation, Position } from '@blueprintjs/core';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
export const LessonCard = ({ id, imageUrl = '', title, noOfSlides = 0, popoverComponent = null }) => {
    const history=useHistory()
    return (
        <Card interactive={true} elevation={Elevation.TWO} className="card_creation">
            <Row className="mb-4">
                <Col span={22}>
                    <Button
                        type="default"
                        size="small"
                        className="fw-400 d-flex"
                        shape="round"
                        style={{
                            color: 'white',
                            boxShadow: 'none',
                            width: '58.67px',
                            height: '20px',
                            left: '350px',
                            top: '474px',
                            background: '#878787',
                            border: ' 1px solid #878787',
                            borderRadius: '10px',
                        }}>
                        Draft
                    </Button>
                </Col>
                <Col span={2}>{popoverComponent}</Col>
            </Row>

            <div>
                <span>
                    <img
                        onClick={() => history.push(`/studio/${id}?type=template`)}
                        className="projectcardImage"
                        src={imageUrl}
                    />
                </span>
            </div>

            <p
                 onClick={() => history.push(`/studio/${id}?type=template`)}
                className="projectCardsTitle">
                {title}
            </p>
            <p style={{ marginBottom: '9px',marginTop:"10px" }}>
                <span style={{ marginLeft: '2%' }}>
                    <span style={{ color: '#6362B0' }}>Template</span> with {noOfSlides} Slides
                </span>
            </p>
        </Card>
    );
};
