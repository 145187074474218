import React, { useEffect, useState } from 'react';
import appConfig from '../../config';
import http from '../../services/http.service';
import { ListContainer } from '../ListContainer';
import { CreateNewCard } from './CreateNewCard';
import { TemplateCard } from './TemplateCard/TemplateCard';
const qs = require('qs');

const selectData = [
    {
        value: 'popularity',
        label: 'Popularity',
    },
];

const LessonListingTab = ({ filter }) => {
    const [data, setData] = useState([]);
    const [allProject, setAllProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            const projects = await http.get(appConfig.collection + '/projects?limit=10');
            setAllProjects(projects.data);
            setData(projects.data);
            setLoading(false);
        };
        fetch();
        return () => setData([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => filterProjects(allProject), [allProject, filter]);

    const filterProjects = (allProject) => {
        const filteredTemplate = [];
        const filterType = ['Template type', 'Created by', 'Themes'];
        if (!Object.keys(filter).filter((key) => filter[key].length).length) setData(allProject);
        else {
            allProject.forEach((item) => {
                const { attributes } = item;
                if (
                    filter?.[filterType[0]]?.includes(attributes?.type) ||
                    filter?.[filterType[1]]?.includes(attributes?.createdBy) ||
                    filter?.[filterType[2]]?.includes(attributes?.theme?.type)
                )
                    filteredTemplate.push(item);
            });
            setData(filteredTemplate);
        }
    };

    const [counter, setCounter] = useState(5);
    const loadMoreHandle = async () => {
        setLoading(true);
        const nextCounter = counter + 5;
        setCounter(nextCounter);
        const result = await http.get(appConfig.collection + '/projects?' + createQuery(null, nextCounter));
        setAllProjects(result.data);
        filterProjects(result.data);
        setLoading(false);
    };

    return (
        <ListContainer
            subText="Choose from hundreds of templates designed specifically for children or build your own!"
            selectProps={{
                options: selectData,
                onChange: () => console.log('on change'),
                defaultValue: 'popularity',
            }}
            loading={loading}
            loadMoreHandle={loadMoreHandle}>
            {!loading && (
                <>
                    <CreateNewCard />
                    {data?.map((item) => (
                        <TemplateCard data={item} key={item.id} showHover />
                    ))}
                </>
            )}
        </ListContainer>
    );
};

const createQuery = (value = null, limit = 10) => {
    if (!value)
        return qs.stringify({
            limit: limit,
        });
    return qs.stringify(
        {
            filters: {
                title: {
                    $contains: value,
                },
            },
        },
        {
            encodeValuesOnly: true, // prettify URL
        }
    );
};

export default LessonListingTab;
