import React from 'react';
import { Col, Input, Row, Select, Spin, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const ListContainer = ({
    subText = '',
    children,
    selectProps = null,
    searchInputProps = {},
    loadMoreHandle = null,
    rowClass = '',
    loading,
}) => {
    return (
        <>
            <Row style={{ marginBottom: '2rem' }} justify="space-between">
                <Col span={8}  >
                    <Input
                        {...searchInputProps}
                        placeholder="Search templates"
                        className="mt-1 search-input"
                        allowClear
                        size="large"
                        prefix={<SearchOutlined /> }
                    />
                </Col>
                <Col span={8} offset={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {selectProps ? (
                        <div className="d-flex align-items-center" style={{ gap: 16 }}>
                            <span className='text-muted'>Sort by</span>
                            <Select
                                {...selectProps}
                                className={`${selectProps?.className}`}
                                style={{ width: 130 }}
                                bordered={false}
                            />
                        </div>
                    ) : null}
                </Col>
            </Row>
            <div style={{ display: 'grid', gap: 24, gridTemplateColumns: 'repeat(auto-fill, minmax(235px, 1fr))' }}>
                {children}
            </div>

            {loading && (
                <div className="loading">
                    <Spin size="large" tip="Loading..." />
                </div>
            )}

            {!loading && loadMoreHandle && (
                <Row className="mt-5">
                    <Col className="d-flex-center primary-color" span={24}>
                        <div>
                            <b onClick={loadMoreHandle} className="cursor-pointer">
                                Load More
                            </b>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};
