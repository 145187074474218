import React, { useState } from 'react';
import { message, Select, Pagination } from 'antd';
import { getCount, getTemplates, deleteItem, addProject, newMediaUpload } from '../../../helpers/apiCalls';
import { Dialog, Classes } from '@blueprintjs/core';
import { getLoggedInUser } from '../../../helpers/authUtils';
import appConfig from '../../../config';
import { getImageFallback } from '../../../utils';
import { TemplateCard } from '../../Card/TemplateCard';
import { TemplateActionPopover } from '../../Card/TemplateCard/components/TemplateActionPopover';
import { CardListContainer } from '../../../pages/my-creation/components/CardListContainer';
import { TemplatePreview } from './components/TemplatePreview';
import { ConfirmationForm } from '../../Forms/ConfirmationForm/ConfirmationForm';
import { Loader } from '../../../pages/my-creation/components/Loader';

const { Option } = Select;

const Template = () => {
    const [deleteId, setDeleteId] = useState(undefined);
    const [showMode, setShowsMode] = useState(false);
    const [showMod, setShowsMod] = useState(false);
    const [copyProject, setCopyproject] = useState({});
    const [thumbnailImageId, setThumbnailImageId] = useState('2017');
    const loggedInUser = getLoggedInUser();
    const [templateData, setTemplateData] = useState([]);
    const [pagination, setPagination] = React.useState({
        current: 1,
        pageSize: 4,
    });
    const handleChange = (value) => {
        let pagination1 = { ...pagination, current: value };
        getTemplate({ pagination: pagination1 });
    };

    const [search, setSearch] = useState({
        searchText: '',
        searchedColumn: '',
    });

    const [loading, setLoading] = React.useState(false);
    console.log('temreload', loading);

    const [total, setTotal] = React.useState([]);
    const onCopyFinish = async (values) => {
        const loggedInUser = getLoggedInUser();
        const username = loggedInUser.user.username;
        const obj = appConfig.initailProject;
        const JSONproject = JSON.stringify(obj);

        let data = {
            data: {
                title: values.title,
                project: copyProject,
                username: username,
                thumbnail: thumbnailImageId,
            },
        };

        await addProject(data)
            .then((res) => {
                message.success('Project Copied Sucessfully !');

                getTemplate({ pagination });
            })
            .catch((err) => {
                message.error('Plz try again!');
            });
        await setThumbnailImageId('2017');
    };

    const handleCopy = (item) => {
        setShowsMode(false);
        setShowsMod(true);
        setCopyproject(item.attributes.template);
    };

    const handleDelete = (item) => {
        setDeleteId(item.id);
        setShowsMod(false);
        setShowsMode(true);

    }

    React.useEffect(() => {
        getCount('Template', loggedInUser.user.username, '')
            .then((res) => {
                setTotal(res.entity);
            })
            .catch((err) => console.log(err));
        getTemplate({ pagination });
    }, [search.searchText]);

    const deleteProject = (id) => {
        let data = { model: 'project', item: id };
        deleteItem('projects', id)
            .then((res) => {
                message.success('Deleted Entry');
                getTemplate({ pagination });
            })
            .catch((err) => {
                message.error('Cannot Delete');
            });
    };

    //Getting data of project from API
    const getTemplate = (params, sortType) => {
        setLoading(true);
        const { limit, start } = getRandomuserParams(params);
        getTemplates('', limit, start, search.searchText, search.searchedColumn, sortType).then((res) => {
            setTemplateData(res.data);
            // console.log("datatem",res.data)
            setPagination({ ...params.pagination });
            setLoading(false);
        });
    };
    const getRandomuserParams = (params) => ({
        limit: params.pagination.pageSize,
        start:
            params.pagination.current > 1
                ? params.pagination.current * params.pagination.pageSize - params.pagination.pageSize
                : 0,
        ...params,
    });

    const onFinish = async (values) => {
        const loggedInUser = getLoggedInUser();
        const username = loggedInUser.user.username;
        const obj = appConfig.initailProject;
        const JSONproject = JSON.stringify(obj);

        let data = {
            data: {
                title: values.title,
                project: JSONproject,
                username: username,
                thumbnail: thumbnailImageId,
            },
        };

        await addProject(data)
            .then((res) => {
                message.success('Successfully created new Course!');

                getTemplate({ pagination });
            })
            .catch((err) => {
                message.error('Plz try again!');
            });
        await setThumbnailImageId('2017');
    };
    window.imgg = thumbnailImageId;

    const handleUpload = (item, name, index = '') => {
        const formData = new FormData();
        formData.append('files', item.file);
        newMediaUpload(formData, item.onProgress)
            .then((res) => {
                setThumbnailImageId(res[0].id);
                item.onSuccess(res, item.file);
            })
            .catch((err) => console.log(err));
    };
    function handleStatusChange(event) {
        // console.log('Select status event', event);
        //  dispatch(elementUpdated(elemId, event, 'display'));
        //  setDisplay(event);
    }
    const statusChildren = [];
    const statusOptions = ['active', 'published', 'draft'];
    for (let i = 0; i < statusOptions.length; i++) {
        statusChildren.push(<Option key={statusOptions[i]}>{statusOptions[i]}</Option>);
    }
    function handleSortChange(event) {
        // console.log('Select sort event', event);
        //  dispatch(elementUpdated(elemId, event, 'display'));
        //  setDisplay(event);
    }
    const sortChildren = [];
    const sortOptions = [' Visibilty', ' Date', ' Name'];
    for (let i = 0; i < sortOptions.length; i++) {
        sortChildren.push(<Option key={sortOptions[i]}>{sortOptions[i]}</Option>);
    }
    const onChange = (item) => {
        const { status } = item.file;
        if (status === 'done') {
            message.success(`${item.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${item.file.name} file upload failed.`);
        }
    };
    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div className="projectcardsContainer">
                    <CardListContainer title={'Recommended Templates ...'}>
                        {makeTemplateCards({ data: templateData, handleCopy, handleDelete })}
                    </CardListContainer>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Pagination onChange={handleChange} defaultCurrent={1} defaultPageSize={4} total={total} />
            </div>
            <Dialog
                enforceFocus={false}
                onClose={() => {
                    setShowsMod(false);
                }}
                style={{ width: '65%' }}
                isOpen={showMod}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={false}>
                <TemplatePreview />
            </Dialog>
            <Dialog
                // title="Add Your Projects"
                // icon="info-sign"
                enforceFocus={false}
                onClose={() => {
                    setShowsMode(false);
                }}
                className="librarydialogcss"
                isOpen={showMode}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={false}>
                <div className={Classes.DIALOG_BODY}>
                    <ConfirmationForm
                        onClose={() => setShowsMode(false)}
                        onCancel={() => setShowsMode(false)}
                        onConfirmation={() => deleteProject(deleteId)}
                        message={'Are you sure you want to delete this project?'}
                    />
                </div>
            </Dialog>
        </div>
    );
};

const imageUrlFallback = (data) => {
    if (!data) return getImageFallback();
    return appConfig.socketURL + data[0]?.attributes.url.replace('/', '');
};

const makeTemplateCards = ({ data = [], handleCopy = () => {}, handleDelete = () => {}, ...rest }) => {
    return data.map((item) => {
        return (
            <TemplateCard
                key={item.id}
                popoverTitle={item.attributes.title}
                id={item.id}
                imageUrl={imageUrlFallback(item.attributes.thumbnail.data)}
                noOfSlides={item.attributes.project?.slides?.length}
                title={item.attributes.title}
                popoverComponent={
                    <TemplateActionPopover
                        title={item.attributes.title}
                        id={item.id}
                        handleCopy={(e) => handleCopy(item)}
                        handleDelete={(e) => handleDelete(item)}
                    />
                }
            />
        );
    });
};

export default Template;
