import React from 'react';
import { Input, Form, InputNumber } from 'antd';

const FormItem = ({ name, rules, type, placeholder, wrapperCol, disabled }) => {
    return (
        <Form.Item name={name} rules={rules} wrapperCol={wrapperCol}>
            {!type ? (
                <Input placeholder={placeholder} disabled={disabled} />
            ) : (
                <InputNumber placeholder={placeholder} disabled={disabled} />
            )}
        </Form.Item>
    );
};

export default FormItem;
