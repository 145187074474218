import React, { useState, useEffect } from 'react';
import { message, Popconfirm, Row, Col, Form, Select, Upload } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getCount, getProjects, deleteItem, addProject, newMediaUpload } from '../../helpers/apiCalls';
import { Label, Dialog, Classes, Button, Card, Elevation, InputGroup, Position, H2 } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { getLoggedInUser } from '../../helpers/authUtils';
import FormItem from '../../components/FormItem';
import appConfig from '../../config';
import { useHistory } from 'react-router-dom';
// import Carousel from 'react-elastic-carousel';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Pagination } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import cover from '../../assets/images/bkp/covers/2.jpg';

import {
    CopyIcon,
    DeleteSvg,
    EditIcon,
    InteractionIcon,
    QuizeIcon,
    SlidesIcon,
    VideoIcon,
    Crossbtn,
} from '../../ProjectSVG';
import Template from '../../components/SubSection/RecommendTemplate';
import Theme from '../../components/ThemeProject';
import { Loader } from '../my-creation/components/Loader';
const { Option } = Select;
const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 20,
    },
};
const uploadProps = {
    name: 'file',
    multiple: false,
};
const uploadbutton = (
    <div>
        <PlusOutlined />
        <div className="uploadbutton">Upload</div>
    </div>
);

const Library = () => {
    const history = useHistory();
    const [showModel, setShowsModel] = useState(false);
    const [deleteId, setDeleteId] = useState(undefined);
    const [showMode, setShowsMode] = useState(false);
    const [showMod, setShowsMod] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showMo, setShowsMo] = useState(false);
    const [type, setType] = useState('');

    const [thumbnailImage, setThumbnailImage] = useState({});
    const [thumbnailImageId, setThumbnailImageId] = useState('2017');
    const [referesh, setReferesh] = useState(true);
    const [form] = Form.useForm();
    const loggedInUser = getLoggedInUser();
    const [events, setEvents] = useState([]);
    const [cardData, setCardData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [pagination, setPagination] = React.useState({
        current: 1,
        pageSize: 4,
    });
    const [minValue, setMinvalue] = useState(0);
    const [maxValue, setMaxvalue] = useState(6);
    const [PopverOverVisibility, setPopverOverVisibility] = useState(false);
    const handleChange = (value) => {
        let pagination1 = { ...pagination, current: value };
        getProject({ pagination: pagination1, type, events });
    };

    const [search, setSearch] = useState({
        searchText: '',
        searchedColumn: '',
    });

    const [loading, setLoading] = React.useState(false);
    const [total, setTotal] = React.useState([]);

    const makeData = (res) => {
        let selectedProjects = [...res];
        const ccardData = selectedProjects.map((item, index) => {
            return (
                <Card interactive={true} key={index} elevation={Elevation.TWO} className="projectcards">
                    {!showPopup && (
                        <Popover2
                            Position={Position.LEFT_BOTTOM}
                            click
                            content={
                                <div className="tabsPopover">
                                    <p className="popoverTitle">{item.attributes.title}</p>
                                    <hr />

                                    <div className="popoverSubtitle">
                                        <Link
                                            className="text-success cursor-pointer me-3"
                                            to={{ pathname: `/studio/${item.id}` }}>
                                            <EditIcon />
                                        </Link>
                                        <span
                                            onClick={() => history.push(`/studio/${item.id}`)}
                                            className="popoverSubtitleText">
                                            Edit Project
                                        </span>
                                    </div>
                                    <div className="popoverSubtitle">
                                        <CopyIcon />

                                        <button
                                            className={`${Classes.POPOVER_DISMISS} ml-4px`}
                                            style={{ border: 'WHITE', backgroundColor: 'WHITE', fontSize: '12px' }}
                                            onClick={() => {
                                                setShowsMod(true);
                                                setShowPopup(false);
                                                setShowsMode(false);
                                                setShowsMo(false);
                                            }}>
                                            Make a Copy of project
                                        </button>
                                    </div>
                                    <div className="popoverSubtitle">
                                        <CopyIcon />

                                        <button
                                            className={`${Classes.POPOVER_DISMISS} ml-4px`}
                                            style={{ border: 'WHITE', backgroundColor: 'WHITE', fontSize: '12px' }}
                                            onClick={() => {
                                                setShowsMo(true);
                                                setShowPopup(false);
                                                setShowsMode(false);
                                                setShowsMod(false);
                                            }}>
                                            Make a Copy of templates
                                        </button>
                                    </div>
                                    <div className="popoverSubtitle">
                                        <DeleteSvg
                                            onClick={() => {
                                                setShowsMode(true);
                                                setDeleteId(item.id);
                                                setShowsMod(false);
                                                setShowsMo(false);
                                            }}></DeleteSvg>

                                        <button
                                            className={`${Classes.POPOVER_DISMISS} ml-4px`}
                                            style={{ border: 'WHITE', backgroundColor: 'WHITE', fontSize: '12px' }}
                                            onClick={() => {
                                                setShowsMode(true);
                                                setDeleteId(item.id);
                                                setShowsMod(false);
                                                setShowsMo(false);
                                            }}>
                                            Move to Trash
                                        </button>
                                    </div>
                                </div>
                            }>
                            <Button icon="more" className="projectcardMore"></Button>
                        </Popover2>
                    )}
                    {item.attributes.thumbnail.data ? (
                        <img
                            onClick={() => history.push(`/studio/${item.id}`)}
                            className="projectcardImage"
                            alt=""
                            src={
                                appConfig.socketURL +
                                item.attributes?.thumbnail?.data[0]?.attributes.url.replace('/', '')
                            }
                        />
                    ) : (
                        <img className="projectcardImage" alt={cover} src={cover} />
                    )}
                    <p onClick={() => history.push(`/studio/${item.id}`)} className="projectCardsTitle">
                        {item.attributes.title}
                    </p>
                    <p style={{ marginBottom: '9px', marginTop: '10px' }}>
                        <SlidesIcon />
                        <span style={{ color: '#0A9764' }}> Lesson </span> with{' '}
                        {item?.attributes?.project?.slides?.length} Slides
                    </p>
                    <div style={{ display: 'flex' }} className="projectCardButtons">
                        <Button onClick={() => history.push(`/studio/${item.id}`)}>KS1</Button>
                        <Button onClick={() => history.push(`/studio/${item.id}`)}>KS1</Button>
                        <Button onClick={() => history.push(`/studio/${item.id}`)}>Lesson</Button>
                    </div>
                </Card>
            );
        });
        setCardData(ccardData);
        const data = selectedProjects.map((item, index) => ({
            index: index + 1,
            key: item.id,
            title: item.attributes.title,
            username: item.attributes.username,
            action: (
                <>
                    <Link className="text-success cursor-pointer me-3" to={{ pathname: `/studio/${item.id}` }}>
                        <EditOutlined />
                    </Link>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => deleteProject(item.id)}
                        onCancel={() => console.log('cancelled')}
                        okText="Yes"
                        cancelText="No">
                        <DeleteOutlined className="text-danger cursor-pointer" />
                    </Popconfirm>
                </>
            ),
        }));
        setProjects(data);
        return data;
    };
    // setting Total no. of Project
    React.useEffect(() => {
        getCount('Project', search.searchText, search.searchedColumn)
            .then((res) => {
                setTotal(res.entity);
            })
            .catch((err) => console.log(err));
        getProject({ pagination }, type, events);
    }, [search.searchText]);
    React.useEffect(() => {
        getProject({ pagination });
    }, []);
    // Deleting  a particular Project
    const deleteProject = (id) => {
        deleteItem('projects', id)
            .then((res) => {
                message.success('Deleted Entry');
                getProject({ pagination });
            })
            .catch((err) => {
                message.error('Cannot Delete');
            });
    };
    const handleSearch = (eventone) => {
        setLoading(true);
        getProject({ pagination }, type, eventone.target.value);
    };

    useEffect(() => {
        setLoading(true);
        getProject({ pagination });
        getProjects()
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => console.log(err));
    }, []);

    //Getting data of project from API
    const getProject = (params, sortType, events) => {
        setLoading(true);
        const { limit, start } = getRandomuserParams(params);
        getProjects('', limit, start, events, search.searchedColumn, sortType).then((res) => {
            let data = makeData(res.data);
            setProjects(data);
            setPagination({ ...params.pagination });
            setLoading(false);
        });
    };
    const getRandomuserParams = (params) => ({
        limit: params.pagination.pageSize,
        start:
            params.pagination.current > 1
                ? params.pagination.current * params.pagination.pageSize - params.pagination.pageSize
                : 0,
        ...params,
    });

    const onFinish = async (values) => {
        const loggedInUser = getLoggedInUser();
        const username = loggedInUser.user.username;
        const obj = appConfig.initailProject;
        const JSONproject = JSON.stringify(obj);
        let data = {
            data: {
                title: values.title,
                project: JSONproject,
                username: username,
                thumbnail: thumbnailImageId,
            },
        };

        await addProject(data)
            .then((res) => {
                message.success('Successfully created new Course!');

                getProject({ pagination });
                setShowsModel(false);
            })
            .catch((err) => {
                message.error('Plz try again!');
            });
        await setThumbnailImageId('2017');
    };
    window.imgg = thumbnailImageId;

    const handleUpload = (item, name, index = '') => {
        const formData = new FormData();
        formData.append('files', item.file);
        newMediaUpload(formData, item.onProgress)
            .then((res) => {
                setThumbnailImage(res[0]);
                setThumbnailImageId(res[0].id);
                item.onSuccess(res, item.file);
            })
            .catch((err) => console.log(err));
    };
    function handleStatusChange(event) {
        //  dispatch(elementUpdated(elemId, event, 'display'));
        //  setDisplay(event);
    }
    const statusChildren = [];
    const statusOptions = ['active', 'published', 'draft'];
    for (let i = 0; i < statusOptions.length; i++) {
        statusChildren.push(<Option key={statusOptions[i]}>{statusOptions[i]}</Option>);
    }
    function handleSortChange(event) {
        let type = '';
        let value =
            event === ' Name'
                ? (type = 'title')
                : event === ' Date'
                ? (type = 'createdAt')
                : event === ' Visibilty'
                ? (type = 'username')
                : '';
        setType(value);
        getProject({ pagination }, value);
    }

    const sortChildren = [];
    const sortOptions = [' Visibilty', ' Date', ' Name'];
    for (let i = 0; i < sortOptions.length; i++) {
        sortChildren.push(<Option key={sortOptions[i]}>{sortOptions[i]}</Option>);
    }
    const onChange = (item) => {
        const { status } = item.file;
        if (status === 'done') {
            message.success(`${item.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${item.file.name} file upload failed.`);
        }
    };
    return (
        <div>
            <div className="banner" style={{ paddingLeft: '32px' }}>
                <Row>
                    <Col span={16}>
                        <H2 className="bannerTitle">Welcome to your Superteach resource library. </H2>
                        <p className="projecttext">
                            Browse our library of high quality lesson templates and ready to made fully customisable{' '}
                            <br />
                            lessons. All our templates use a wizard assistant that makes creating new lessons <br />
                            lightening fast!
                        </p>
                    </Col>
                    <Col span={8}>
                        <img
                            alt="img"
                            className="libraryimgcss"
                            src="https://images.pexels.com/photos/8613089/pexels-photo-8613089.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        />
                    </Col>
                </Row>
            </div>
            <div className="librarybrowermaincss">
                <Row className="librarybrowersecondcss">
                    <Col span={10}>
                        <H2 className="bannerProjectSubtitle ">Browse resource library</H2>
                    </Col>
                    <Col span={14} className="libraryinputgroupcss">
                        <Row className="libraryrowcss" span={24}>
                            <Col span={8}>
                                <InputGroup
                                    className="tabsSearchButton setHeightInput"
                                    placeholder="Search by Keywords"
                                    leftIcon="search"
                                    asyncControl={true}
                                    onChange={(eventone) => handleSearch(eventone)}
                                />
                            </Col>
                            <Col span={5}>
                                <Select
                                    className="projectsSelect"
                                    name="status"
                                    placeholder="Themes"
                                    onChange={handleSortChange}>
                                    {sortChildren}
                                </Select>
                            </Col>

                            <Col span={5}>
                                <Select
                                    className="projectsSelect"
                                    name="status"
                                    placeholder="Type"
                                    onChange={handleStatusChange}>
                                    {statusChildren}
                                </Select>
                            </Col>
                            <Col span={5}>
                                <Select
                                    className="projectsSelect"
                                    name="status"
                                    placeholder="Age"
                                    onChange={handleStatusChange}>
                                    {statusChildren}
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <div className="projectcardsContainer">
                    <div id="projects" className="librarycarddatacss">
                        {cardData && cardData.length > 0 && cardData.slice(minValue, maxValue).map((val) => val)}
                    </div>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination onChange={handleChange} defaultCurrent={1} defaultPageSize={4} total={total} />
            </div>

            <Template />

            <Theme />
            <Dialog
                title="Add Your Projects"
                icon="info-sign"
                enforceFocus={false}
                onClose={() => {
                    setShowsModel(false);
                }}
                isOpen={showModel}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={false}>
                <div className={Classes.DIALOG_BODY}>
                    <Row>
                        <Col span={24}>
                            <Form
                                onValuesChange={() => setReferesh(!referesh)}
                                {...layout}
                                form={form}
                                name="control-hooks"
                                onFinish={onFinish}>
                                <div className="d-flex mb-4 px-3 align-items-center justify-content-between">
                                    <h2>Add Project</h2>
                                    <div>
                                        <button
                                            className="rounded-pill outline-none  text-light"
                                            type="submit"
                                            style={{ background: '#6362B0', border: 'none', padding: '10px 15px' }}>
                                            Save & Publish
                                        </button>
                                    </div>
                                </div>

                                <div className="card p-4 shadow schoolForm">
                                    <Row>
                                        <Col span={12}>
                                            <Label>Project Title</Label>
                                            <FormItem
                                                name="title"
                                                wrapperCol={{ span: 22 }}
                                                placeholder="Enter Projects"
                                            />
                                        </Col>
                                        <Col>
                                            <Label>Add Thumbnail</Label>
                                            <Upload
                                                {...uploadProps}
                                                maxCount={1}
                                                customRequest={(item) => handleUpload(item)}
                                                listType="picture-card"
                                                onChange={onChange}>
                                                {uploadbutton}
                                            </Upload>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Dialog>
            <Dialog
                // title="Add Your Projects"
                // icon="info-sign"
                enforceFocus={false}
                onClose={() => {
                    setShowsMode(false);
                    setShowPopup(false);
                }}
                className="librarydialogcss"
                isOpen={showMode}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={false}>
                <div className={Classes.DIALOG_BODY}>
                    <Row style={{ justifyContent: 'flex-end' }}>
                        {' '}
                        <button
                            minimal={false}
                            className="librarydiacrossbuttoncss"
                            onClick={() => {
                                setShowsMode(false);
                            }}>
                            <Crossbtn />
                        </button>
                    </Row>
                    <Row>
                        <Col span={24} className="libarydiacolonecss">
                            Are you sure you want to delete this project?
                        </Col>
                        <Col span={24}>
                            <Button className="librarycanclecss" onClick={() => setShowsMode(false)}>
                                Cancel
                            </Button>
                            <Button className="librarycanclecs" onClick={() => deleteProject(deleteId)}>
                                Yes
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Dialog>

            <Dialog
                enforceFocus={false}
                onClose={() => {
                    setShowsMod(false);
                    setShowPopup(false);
                }}
                style={{ width: '59%' }}
                isOpen={showMod}
                onOpening={showPopup}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={false}>
                <div className={Classes.DIALOG_BODY}>
                    <Row style={{ justifyContent: 'flex-end' }}>
                        {' '}
                        <button
                            minimal={false}
                            className="librarydiacrossbuttoncss"
                            onClick={() => {
                                setShowsMod(false);
                            }}>
                            <Crossbtn />
                        </button>
                    </Row>
                    <Row style={{ marginTop: '15px' }}>
                        <Col className="librarycarouselcss">
                            {/* <div
                              > */}

                            <Carousel>
                                <div>
                                    <img alt="img" src="/img1.png" className="librarycarouselimgcss" />
                                </div>
                                <div>
                                    <img alt="img" src="/img2.jpg" className="librarycarouselimgcss" />
                                </div>
                                <div>
                                    <img alt="img" src="/img3.jpg" className="librarycarouselimgcss" />
                                </div>
                            </Carousel>

                            {/* </div> */}
                        </Col>
                        <Col style={{ marginLeft: '50%' }}>
                            <Col span={24} className="librarynamecss">
                                A Balanced Diet
                            </Col>
                            <Col>
                                <Row className="projectCardButtons">
                                    <Button className="buttonproject">KS1</Button>
                                    <Button className="buttonproject">KS2</Button>
                                </Row>
                            </Col>
                            <Col className="librarytextinsidecss">
                                A year 4 lesson that teaches students what a balanced diet is and why it is important.
                            </Col>
                            <Row className="libraryiconcss">
                                <Row>
                                    <Col>
                                        <SlidesIcon />
                                    </Col>
                                    <Col className="librarycolcss">
                                        <span style={{ color: '#0A9764' }}> Lesson </span> with 12 Slides
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: '30px' }}>
                                    <Col>
                                        <QuizeIcon />
                                    </Col>
                                    <Col className="librarycolcss">2 Quizzes</Col>
                                </Row>
                            </Row>
                            <Row className="libraryiconcss">
                                <Row>
                                    <Col>
                                        <InteractionIcon />
                                    </Col>
                                    <Col className="librarycolcss">1 Interactive</Col>
                                </Row>
                                <Row style={{ marginLeft: '80px' }}>
                                    <Col>
                                        <QuizeIcon />
                                    </Col>
                                    <Col className="librarycolcss">3 Quizzes</Col>
                                </Row>
                            </Row>
                            <Row className="libraryiconcss">
                                <Col>
                                    <VideoIcon />
                                </Col>
                                <Col className="librarycolcss">2 Videos</Col>
                            </Row>
                            <Row span={24} className="libraryiconcss">
                                <Button className="librarylessbtncss">Customise this lesson</Button>
                            </Row>
                            <Row className="librarytextcss">
                                100% fully customisable lesson <br /> Curated by learning experts <br /> Share and
                                publish with your community
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Dialog>

            <Dialog
                // title="Add Your Projects"
                // icon="info-sign"
                enforceFocus={false}
                onClose={() => {
                    setShowsMo(false);
                }}
                style={{ width: '59%' }}
                isOpen={showMo}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={false}>
                <div className={Classes.DIALOG_BODY}>
                    <Row style={{ justifyContent: 'flex-end' }}>
                        {' '}
                        <button
                            minimal={false}
                            className="librarydiacrossbuttoncss"
                            onClick={() => {
                                setShowsMo(false);
                            }}>
                            <Crossbtn />
                        </button>
                    </Row>
                    <Row style={{ marginTop: '15px' }} xs={6} sm={12} md={12} lg={24} xl={24} xxl={24}>
                        <Col className="librarycarouselcss">
                            <DndProvider backend={HTML5Backend}>
                                <Carousel>
                                    <div>
                                        <img alt="img" src="/img1.png" className="librarycarouselimgcss" />
                                    </div>
                                    <div>
                                        <img alt="img" src="/img2.jpg" className="librarycarouselimgcss" />
                                    </div>
                                    <div>
                                        <img alt="img" src="/img3.jpg" className="librarycarouselimgcss" />
                                    </div>
                                </Carousel>
                            </DndProvider>
                            {/* </div> */}
                        </Col>
                        <Col style={{ marginLeft: '50%' }}>
                            <Col span={24} className="librarynamecss">
                                Assembly Template 1
                            </Col>
                            <Col>
                                <Row className="projectCardButtons">
                                    <Button className="buttonprojec">Beach theme</Button>
                                    <Button className="buttonprojec">Blue color</Button>
                                </Row>
                            </Col>
                            <Col className="librarytextinsidecss">
                                Lesson template powered by the SuperTeach Wizard Assistant, who helps you fill out your
                                presentation with ease!
                            </Col>
                            <Row className="libraryiconcss">
                                <Row>
                                    <Col>
                                        <SlidesIcon />
                                    </Col>
                                    <Col className="librarycolcss">
                                        {' '}
                                        <span style={{ color: '#6362B0' }}>Template</span> with 9 Slides
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: '30px' }}>
                                    <Col>
                                        <QuizeIcon />
                                    </Col>
                                    <Col className="librarycolcss">2 Quizzes</Col>
                                </Row>
                            </Row>
                            <Row className="libraryiconcss">
                                <Row>
                                    <Col>
                                        <InteractionIcon />
                                    </Col>
                                    <Col className="librarycolcss">1 Interactive</Col>
                                </Row>
                                <Row style={{ marginLeft: '80px' }}>
                                    <Col>
                                        <QuizeIcon />
                                    </Col>
                                    <Col className="librarycolcss">3 Quizzes</Col>
                                </Row>
                            </Row>
                            <Row className="libraryiconcss">
                                <Col>
                                    <VideoIcon />
                                </Col>
                                <Col className="librarycolcss">2 Videos</Col>
                            </Row>
                            <Row span={24} className="libraryiconcss">
                                <Button
                                    className="librarytempbtncss"
                                    onClick={() => {
                                        setShowsMod(false);
                                        setShowsMo(false);
                                    }}>
                                    Use this template
                                </Button>
                            </Row>
                            <Row span={24} className="libraryiconcss">
                                <Link to={{ pathname: 'wizads', state: { id: 277 } }}>
                                    <Button className="librarytempbtncss"> Wizard based template builder </Button>
                                </Link>
                            </Row>
                            <Row className="librarytexttwocss">
                                Wizard based template builder - <b> Build in 5 minutes </b> <br /> Interactive
                                techniques to engage students <br /> Share and publish with your community
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Dialog>
        </div>
    );
};

export default Library;
