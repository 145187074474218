import React, { Component } from 'react';
import Routes from './routes/Routes';
import { EuiProvider } from '@elastic/eui';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@elastic/eui/dist/eui_theme_light.css';
import 'antd/dist/antd.css';

// setup fake backend
import { configureFakeBackend } from './helpers';
//Css
import './style.css';
import './vanimation.css';
// Themes
// default
import './assets/scss/theme.scss';
import ReactGA from 'react-ga';
// dark
// import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';

ReactGA.initialize('UA-171648221-1', {
    debug: false,
    titleCase: false,
    gaOptions: {
        siteSpeedSampleRate: 100,
    },
});

// configure fake backend
configureFakeBackend();

/**
 * Main app component
 */
class App extends Component {
    render() {
        return <EuiProvider><Routes /></EuiProvider>;
    }
}

export default App;
