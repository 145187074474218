/* eslint-disable */
import React from 'react';
import { Breadcrumb, Modal, Spin } from 'antd';
import { pick } from 'lodash';
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import appConfig from '../config';
import CustomTransfer from '../IndividualComponent/CustomTransfer';
import FormInput from '../IndividualComponent/FormInput';
import NavigationButtonGroup from '../IndividualComponent/NavigationButtonGroup';
import ThemeView from '../IndividualComponent/ThemeView';
import TemplateCreationModal from '../modals/TemplateCreationModal';
import { createData, fetchData, updateData } from '../services/template.service';
import { settingsDiff } from '../utils';
import { useHistory } from 'react-router-dom';
import { EuiButton, EuiStepsHorizontal } from '@elastic/eui';
import { LessonTemplatePreview } from './PreviewModal';

const CreationModule = ({ module = 'template' }) => {
    const [step, setStep] = useState(1);
    const history = useHistory();
    const cookies = new Cookies();
    const user = cookies.get('user', { domain: appConfig.cookiesDoamin });
    const userObj = user ? (typeof user == 'object' ? user.user : JSON.parse(user)?.user) : null;
    const userName = userObj?.username;
    const [templateData, setTemplateData] = useState({
        title: '',
        description: '',
        slides: [],
        template: [],
        project: {},
        username: userName,
        theme: undefined,
    });
    const [documentData, setDocumentData] = useState({});
    const [slidesData, setSlidesData] = useState([]);
    const [themesData, setThemesData] = useState([]);
    const [themeQuestionBySlide, setThemeQuestionBySlide] = useState({});
    const [themeSlideStep, setThemeSlideStep] = useState(1);
    const [isTemplateCreated, setIsTemplateCreated] = useState(false);
    const [nextButtomDisabled, setNextButtomDisabled] = useState(true);
    const [nextButtomLoading, setNextButtomLoading] = useState(false);
    const [projectData, setProjectData] = useState({});
    const [showDesignTemplateModal, setShowDesignTemplateModal] = useState({});
    const [loading, setLoading] = useState(false);
    const [editDocument, setEditDocument] = useState(new URLSearchParams(history.location.search).get('id'));

    let content = {};
    switch (module) {
        case 'lesson':
            content = {
                breadCrumbMenu: {
                    0: 'Lesson Builder',
                    1: 'My Lessons',
                    2: 'Create my own lesson',
                },
                header: {
                    title: 'Build my own lesson',
                    subTitle: 'The possibilities are endless. Create your own lesson in four easy steps!',
                },
                steps: ['Create', 'Slide Selection', 'Theme Selection', 'Add content'],
                step_1: {
                    title: 'Name your Lesson',
                    description: 'Add a brief description',
                },
                step_2: {
                    subTitle: `Drag and drop the slides you want into your own lessons. To reorder the slides, 
                        use the arrows to move them up and down.`,
                    slidesTitleName: 'My Lesson',
                },
                URL: `${appConfig.collection}/projects`,
            };
            break;

        default:
            content = {
                breadCrumbMenu: {
                    0: 'Template Builder',
                    1: 'My Templates',
                    2: 'Create my own template',
                },
                header: {
                    title: 'Create my own template',
                    subTitle: 'The possibilities are endless. Create your own template in three easy steps!',
                },
                steps: ['Create', 'Slide Selection', 'Theme Selection'],
                step_1: {
                    title: 'Name your template',
                    description: 'Add a brief description',
                },
                step_2: {
                    subTitle: `Drag and drop the slides you want into your own template. To reorder the slides, 
                        use the arrows to move them up and down.`,
                    slidesTitleName: 'My Template',
                },
                URL: `${appConfig.collection}/templates`,
            };
            break;
    }

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            fetchData(`${appConfig.collection}/slide-types`).then(({ response: sildesRawData }) => {
                let slidesData = {};
                sildesRawData?.map(({ attributes, id }) => {
                    return (slidesData[attributes.type] = [
                        ...(slidesData?.[attributes?.type] ? slidesData[attributes.type] : []),
                        { ...attributes, id },
                    ]);
                });
                // let blank = 'blank';
                // let firstBlankData = Object.keys(slidesData).splice(Object.keys(slidesData).indexOf(blank), 1);

                // console.log('SlideData', slidesData, sildesRawData, firstBlankData);
                setSlidesData(slidesData);
            });
            fetchData(`${appConfig.collection}/themes`).then(({ response: themesRawData }) => {
                let themesData = {};
                themesRawData?.map(({ attributes, id }) => {
                    return (themesData[id] = {
                        title: attributes?.title,
                        description: attributes?.description,
                        image: attributes?.image,
                        theme: attributes?.theme,
                        id,
                    });
                });
                setThemesData(themesData);
            });
            if (editDocument) {
                const { response, error } = await fetchData(`${content?.URL}/${editDocument}`);
                if (!error) {
                    const allowedKeys = ['name', 'username', 'title', 'description', 'slides', 'project', 'template'];
                    setTemplateData({
                        ...pick(response?.attributes, allowedKeys),
                        theme: response?.attributes?.theme?.data?.id,
                    });
                    setDocumentData({
                        ...pick(response?.attributes, allowedKeys),
                        theme: response?.attributes?.theme?.data?.id,
                    });
                } else {
                    history.replace({ search: null });
                    setEditDocument('');
                }
            }
            setLoading(false);
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (step !== 1 && !editDocument) setStep(1);
        if (step === 1) {
            if (templateData.title) setNextButtomDisabled(false);
            else setNextButtomDisabled(true);
        } else if (step === 2) {
            if (templateData.slides?.filter((ele) => !ele?.name).length) setNextButtomDisabled(true);
            else setNextButtomDisabled(false);
        } else if (step === 3) {
            if (templateData.theme) setNextButtomDisabled(false);
            else setNextButtomDisabled(true);
        } else if (step === 4) {
            if (module === 'template') setIsTemplateCreated(true);
            else if (module === 'lesson') {
                let projectData = [];
                const savedProjectData = {};
                const savedProjectQuestion = {};
                templateData.project?.slides?.map((ele) => {
                    savedProjectData[ele?.id] = ele;
                    ele?.elements?.map((item) => {
                        savedProjectQuestion[item.id] = item;
                    });
                });
                Object.keys(templateData.slides).map((key, index) => {
                    const slides = {};
                    themesData[templateData.theme]?.theme?.slides.map((slide) => {
                        slides[slide.slideType] = slide;
                    });
                    const templateSlide = slides[templateData?.slides?.[key]?.name];
                    projectData = [
                        ...projectData,
                        savedProjectData?.[templateSlide?.id]
                            ? {
                                  ...templateSlide,
                                  elements: templateSlide?.elements?.map((item) =>
                                      savedProjectQuestion?.[item?.id] ? savedProjectQuestion?.[item?.id] : item
                                  ),
                              }
                            : templateSlide,
                    ];
                    return templateSlide?.elements || [];
                });
                setThemeQuestionBySlide(projectData[themeSlideStep - 1]);
                setProjectData(projectData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, editDocument, templateData]);

    const onNextStep = async () => {
        try {
            setNextButtomLoading(true);
            let result = {};
            let changed = false;
            let changedSettings = {};
            let isCompleted = false;
            switch (module) {
                case 'template':
                    if (step === 3) {
                        isCompleted = true;
                    }
                // eslint-disable-next-line no-fallthrough
                case 'lesson':
                    if (step === 4) {
                        const changeValues = handleLessonSubmit();
                        changed = changeValues.changed;
                        changedSettings = changeValues.changedSettings;
                        isCompleted = true;
                        break;
                    }

                // eslint-disable-next-line no-fallthrough
                default:
                    const changeValues = settingsDiff(templateData, documentData);
                    changed = changeValues.changed;
                    changedSettings = changeValues.changedSettings;
            }

            if (changed) {
                if (editDocument) result = await updateData(content?.URL + `/${editDocument}`, changedSettings);
                else result = await createData(content?.URL, changedSettings);
            }
            const { error, response } = result;
            if (!error) {
                if (response) {
                    setEditDocument(response?.id);
                    const allowedKeys = ['name', 'username', 'title', 'description', 'slides', 'project', 'template'];
                    setDocumentData({ ...pick(response?.attributes, allowedKeys), theme: templateData?.theme });
                }
                if (!editDocument)
                    history.replace({
                        search: '?id=' + response?.id,
                    });

                if (isCompleted) setIsTemplateCreated(true);
                else setStep((step) => step + 1);
            }
        } finally {
            setNextButtomLoading(false);
            setNextButtomDisabled(true);
        }
    };
    const onPreviousStep = () => {
        setThemeSlideStep(1);
        setStep((step) => step - 1);
    };
    const handleStep4SlideChange = (step = 'next') => {
        const stepTowards = step === 'next' ? 1 : -1;
        const prevProjectData = projectData;
        prevProjectData[themeSlideStep - 1] = themeQuestionBySlide;
        setProjectData(prevProjectData);
        setThemeQuestionBySlide(projectData[themeSlideStep + stepTowards - 1]);
        setThemeSlideStep(themeSlideStep + stepTowards);
    };

    const handleLessonSubmit = () => {
        let updatedProjectData = projectData;
        updatedProjectData[themeSlideStep - 1] = themeQuestionBySlide;
        setProjectData(updatedProjectData);
        updatedProjectData = updatedProjectData.map((ele) => {
            return { ...ele, elements: ele?.elements?.filter((ques) => ques?.content?.text) };
        });
        const project = {
            ...themesData[templateData.theme]?.theme,
            slides: updatedProjectData,
        };
        setTemplateData({
            ...templateData,
            project,
        });
        return {
            changed: true,
            changedSettings: { project },
        };
    };

    if (!userName)
        return (
            <div className="loading">
                <Spin size="large" tip="Loading..." />
            </div>
        );
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 40,
                background: 'white',
                minHeight: 'calc(100vh - 50px)',
            }}>
            <div className="menu-header">
                <Breadcrumb separator=">">
                    {Object.entries(content?.breadCrumbMenu).map(([key, value]) => (
                        <Breadcrumb.Item key={key} href="#">
                            {value}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
            </div>
            <div className="template-creation-module-container">
                <div className="template-creation-module">
                    <div className="header">
                        <div className="title">{content?.header?.title}</div>
                        <div className="subtitle subtitle-padding">{content?.header?.subTitle}</div>
                    </div>

                    <EuiStepsHorizontal
                        className="stepper"
                        style={{ width: '123%' }}
                        steps={content?.steps?.map((stepName, index) => {
                            return {
                                title: stepName,
                                status: step > index + 1 ? 'complete' : step === index + 1 ? 'current' : 'disabled',
                                onClick: () => null,
                            };
                        })}
                    />

                    {loading ? (
                        <div className="loading">
                            <Spin size="large" tip="Loading..." />
                        </div>
                    ) : (
                        <>
                            {step === 1 && (
                                <div className="step-1">
                                    {Object.keys(content?.step_1).map((input, index) => {
                                        return (
                                            <FormInput
                                                key={index}
                                                label={content.step_1[input]}
                                                value={templateData[input]}
                                                onChange={(value) =>
                                                    setTemplateData({
                                                        ...templateData,
                                                        [input]: value,
                                                    })
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            )}

                            {step === 2 && (
                                <div className="step-2">
                                    <div className="template-header">
                                        <label className="subtitle">{content?.step_2?.subTitle}</label>
                                    </div>
                                    <CustomTransfer
                                        dataSource={slidesData}
                                        dataTarget={templateData?.slides}
                                        slidesTitleName={content?.step_2?.slidesTitleName}
                                        dataUpdated={(slides) =>
                                            setTemplateData({
                                                ...templateData,
                                                slides,
                                            })
                                        }
                                    />
                                </div>
                            )}

                            {step === 3 && (
                                <div className="step-3">
                                    <div className="template-header">
                                        <label className="subtitle">
                                            Use your school brand colours, pick from one of our pre-existing themes, or
                                            use our default
                                            <span style={{ color: '#5C3091', fontWeight: 700 }}>
                                                {' '}
                                                Superteach theme (it’s still super!)
                                            </span>
                                        </label>
                                    </div>
                                    <div className="template-images-container">
                                        {Object.keys(themesData).map((item, _) => {
                                            const ele = themesData[item];
                                            return (
                                                <ThemeView
                                                    key={_}
                                                    title={ele.title}
                                                    isSelected={ele.id === templateData?.theme}
                                                    description={ele.description}
                                                    onClick={() =>
                                                        setTemplateData({
                                                            ...templateData,
                                                            theme: ele.id,
                                                        })
                                                    }
                                                    onPreview={() => setShowDesignTemplateModal(ele)}
                                                />
                                            );
                                        })}
                                    </div>
                                    {showDesignTemplateModal?.title && (
                                        <Modal
                                            open={true}
                                            footer={null}
                                            title={null}
                                            width={700}
                                            className="primary-modal"
                                            destroyOnClose
                                            onCancel={() => setShowDesignTemplateModal(false)}>
                                            <LessonTemplatePreview
                                                themeViewModal
                                                title="Colourful Shapes theme"
                                                description="A fun, logic-based Easter-themed activity where students read clues to solve the mystery."
                                                images={[
                                                    { id: 1 },
                                                    { id: 1 },
                                                    { id: 1 },
                                                    { id: 1 },
                                                    { id: 1 },
                                                    { id: 1 },
                                                ]}
                                            />
                                        </Modal>
                                    )}
                                </div>
                            )}

                            {module === 'lesson' && step === 4 && (
                                <div className="step-4">
                                    <div className="template-header">
                                        <label className="subtitle">
                                            Now add your content into the slides, we’ll ensure your lesson looks great
                                            with the help of our
                                            <span style={{ color: '#7d42c5', fontWeight: 700 }}>
                                                {' '}
                                                lesson builder assistant!
                                            </span>
                                        </label>
                                    </div>
                                    <div className="lesson-builder">
                                        <div className="back-button">
                                            {themeSlideStep > 1 && (
                                                <div
                                                    className="clickable"
                                                    onClick={() => handleStep4SlideChange('prev')}>
                                                    Back
                                                </div>
                                            )}
                                        </div>
                                        <div className="lesson-builder-container">
                                            <div className="header">Let’s create your lesson</div>
                                            <div className="lesson-builder-slides-conatiner">
                                                {themeQuestionBySlide?.elements?.length ? (
                                                    themeQuestionBySlide?.elements?.map((item, _) => {
                                                        return (
                                                            <div className="question-container" key={_}>
                                                                <div className="question">{item?.question}</div>
                                                                <div className="options">
                                                                    <div className="option">
                                                                        <FormInput
                                                                            value={item?.content?.text}
                                                                            onChange={(answer) => {
                                                                                const quesDetail = {
                                                                                    ...item,
                                                                                    content: { text: answer },
                                                                                };
                                                                                let elements =
                                                                                    themeQuestionBySlide?.elements;
                                                                                elements[_] = quesDetail;
                                                                                setThemeQuestionBySlide({
                                                                                    ...themeQuestionBySlide,
                                                                                    elements,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="No-question">No questions yet, Move next.</div>
                                                )}
                                            </div>

                                            <div className="footer">
                                                <div className="slide-no">
                                                    {themeSlideStep} slide{' '}
                                                    <span className="text-muted">of {projectData?.length}</span>
                                                </div>
                                                <div className="next-slide">
                                                    {themeSlideStep < projectData?.length ? (
                                                        <EuiButton onClick={() => handleStep4SlideChange('next')}>
                                                            Next Slide
                                                        </EuiButton>
                                                    ) : (
                                                        <EuiButton onClick={onNextStep}>Submit</EuiButton>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <TemplateCreationModal
                                id={editDocument}
                                module={module}
                                visible={isTemplateCreated}
                                onCancel={() => setIsTemplateCreated(false)}
                            />

                            <NavigationButtonGroup
                                showNext={step < 4}
                                onNextStep={onNextStep}
                                nextButtonLoading={nextButtomLoading}
                                nextButtomDisabled={nextButtomDisabled}
                                showPrev={step > 1 && step <= 4}
                                onPreviousStep={onPreviousStep}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CreationModule;
