// Cardinal Law:
//  1. no side effects
//  2. output depends on input

import { cloneDeep, isArray, isEqual, isObject } from 'lodash';
import cover from '../assets/images/bkp/covers/2.jpg';
import appConfig from '../config';

export const getImageFallback = () => cover;

export const imagesObjectReMap = (imagesObject = {}) => {
    return imagesObject?.length
        ? imagesObject.map((k) => {
              return { imageUrl: appConfig.socketURL + k.attributes.url, id: k.id };
          })
        : [];
};

export const settingsDiff = (newSettings, originalSettings, ignoreList = []) => {
    // ignore_list takes a list of (str) keys to ignore
    // Returns an object with the keys/values that have changed from their initial state
    let _newSettings = cloneDeep(newSettings);
    let _originalSettings = cloneDeep(originalSettings);
    // Deleting ignored fields
    ignoreList.forEach((ignore_attribute) => {
        if (ignore_attribute in _newSettings) delete _newSettings[ignore_attribute];
        if (ignore_attribute in _originalSettings) delete _originalSettings[ignore_attribute];
    });
    // calculating changed settings
    let changedSettings = {};
    Object.keys(_newSettings).forEach((setting) => {
        let newSetting = _newSettings[setting];
        let originalSetting = _originalSettings[setting];

        if (isObject(newSetting)) {
            if (!isEqual(originalSetting, newSetting)) changedSettings[setting] = newSetting;
        } else if (isArray(newSetting)) {
            if (!isEqual(originalSetting, newSetting)) changedSettings[setting] = newSetting;
        } else if (originalSetting !== newSetting) {
            changedSettings[setting] = newSetting;
        }
    });
    return { changed: Object.keys(changedSettings).length > 0, changedSettings };
};
