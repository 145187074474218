import React from 'react';
import { Row, Col } from 'antd';
import { Button, Classes } from '@blueprintjs/core';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
    InteractionIcon,
    QuizeIcon,
    SlidesIcon,
    VideoIcon,
    Crossbtn,
} from '../../../ProjectSVG';


export const ThemePreview = ({ onClose = () => { } }) => {
    return (
        <div className={Classes.DIALOG_BODY}>
            <Row style={{ justifyContent: 'flex-end' }}>
                {' '}
                <button
                    minimal={false}
                    className="librarydiacrossbuttoncss"
                    onClick={() => onClose}>
                    <Crossbtn />
                </button>
            </Row>
            <Row style={{ marginTop: '15px' }}>
                <Col className="librarycarouselcss">
                    {/* <div
                              > */}

                    <Carousel>
                        <div>
                            <img src="/img1.png" className="librarycarouselimgcss" />
                        </div>
                        <div>
                            <img src="/img2.jpg" className="librarycarouselimgcss" />
                        </div>
                        <div>
                            <img src="/img3.jpg" className="librarycarouselimgcss" />
                        </div>
                    </Carousel>

                    {/* </div> */}
                </Col>
                <Col style={{ marginLeft: '50%' }}>
                    <Col span={24} className="librarynamecss">
                        A Balanced Diet
                    </Col>
                    <Col>
                        <Row className="projectCardButtons">
                            <Button className="buttonproject">KS1</Button>
                            <Button className="buttonproject">KS2</Button>
                        </Row>
                    </Col>
                    <Col className="librarytextinsidecss">
                        A year 4 lesson that teaches students what a balanced diet is and why it is important.
                    </Col>
                    <Row className="libraryiconcss">
                        <Row>
                            <Col>
                                <SlidesIcon />
                            </Col>
                            <Col className="librarycolcss">
                                <span style={{ color: '#0A9764' }}> Lesson </span> with 12 Slides
                            </Col>
                        </Row>
                        <Row style={{ marginLeft: '30px' }}>
                            <Col>
                                <QuizeIcon />
                            </Col>
                            <Col className="librarycolcss">2 Quizzes</Col>
                        </Row>
                    </Row>
                    <Row className="libraryiconcss">
                        <Row>
                            <Col>
                                <InteractionIcon />
                            </Col>
                            <Col className="librarycolcss">1 Interactive</Col>
                        </Row>
                        <Row style={{ marginLeft: '80px' }}>
                            <Col>
                                <QuizeIcon />
                            </Col>
                            <Col className="librarycolcss">3 Quizzes</Col>
                        </Row>
                    </Row>
                    <Row className="libraryiconcss">
                        <Col>
                            <VideoIcon />
                        </Col>
                        <Col className="librarycolcss">2 Videos</Col>
                    </Row>
                    <Row span={24} className="libraryiconcss">
                        <Button className="librarylessbtncss">Customise this lesson</Button>
                    </Row>
                    <Row className="librarytextcss">
                        100% fully customisable lesson <br /> Curated by learning experts <br /> Share and
                        publish with your community
                    </Row>
                </Col>
            </Row>
        </div>
    );
}