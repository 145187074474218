import React from 'react';
import { Button, Card, Elevation, Position } from '@blueprintjs/core';
import { Link, useHistory } from 'react-router-dom';
import { SlidesIcon } from '../../../ProjectSVG';
import ActionPopover from './components/TemplateActionPopover';

export const TemplateCard = ({ id, imageUrl = '', title, noOfSlides = 0, popoverComponent = null }) => {
    const history = useHistory();
    return (
        <Card interactive={true} elevation={Elevation.TWO} className="projectcards">
            {popoverComponent}
            <Link
                to={{
                    pathname: `/studio/${id}`,
                    state: { type: 'template' },
                    search: '?type=template',
                }}>
                <img alt="template" className="projectcardImage" src={imageUrl} />
            </Link>

            <p onClick={() => history.push(`/studio/${id}?type=template`)} className="projectCardsTitle">
                {title}
            </p>
            <p style={{ marginBottom: '9px', marginTop: '10px' }}>
                <SlidesIcon />
                <span style={{ marginLeft: '2%' }}>
                    <span style={{ color: '#6362B0' }}>Template</span> with {noOfSlides} Slides
                </span>
            </p>
            <div style={{ display: 'flex' }} className="projectCardButtons">
                <Button onClick={() => history.push(`/studio/${id}?type=template`)}>KS1</Button>
                <Button onClick={() => history.push(`/studio/${id}?type=template`)}>KS1</Button>
                <Button onClick={() => history.push(`/studio/${id}?type=template`)}>Lesson</Button>
            </div>
        </Card>
    );
};
