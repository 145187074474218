import React, { useState, useEffect } from 'react';
import { message, Popconfirm, Upload, Row, Select } from 'antd';
import { Button, Dialog, Classes, Tooltip, InputGroup, Card, Elevation, Position } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { Popover2 } from '@blueprintjs/popover2';
import { getAssets, newMediaUpload, newAssets } from '../../helpers/apiCalls';
import appConfig from '../../config';
import { PlusOutlined } from '@ant-design/icons';
import { Pagination } from 'antd';
import { DeleteSvg, DownloadIcon, UploadmediaIcon } from '../../ProjectSVG';

const { Option } = Select;
const uploadProps = {
  name: 'file',
  multiple: false,
};

const Asset = () => {
  const [totalMedia, setTotalMedia] = useState();
  const [type, setType] = useState('background');
  const [totalType, setTotalType] = useState();
  const [total, setTotal] = useState(1);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(8);
  const [mediaModel, setMediamodel] = useState(false);
  const [assetImage, setAssetImage] = useState({});
  const [loading, setLoading] = useState(false);

  const uploadbutton = (
    <div>
      <PlusOutlined />
      <div className="uploadbutton">Upload</div>
    </div>
  );

  const getType = (data) => {
    let unique = data.data
      .filter((value) => value.attributes.contenttype !== null)
      .filter((value, index, self) => {
        return self.map((e) => e.attributes.contenttype).indexOf(value.attributes.contenttype) === index;
      });

    return unique;
  };
  useEffect(() => {
    setLoading(true);
    getAssets()
      .then((res) => {
        setTotalMedia(res);
        let count = res.data.filter((data) => data.attributes.contenttype === type);
        const allUniqueType = getType(res);
        setTotalType(allUniqueType);
        setTotal(count.length);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [type]);

  useEffect(() => {
    if (totalMedia) {
      let count = totalMedia.data.filter((data) => data.attributes.contenttype === type);
      setTotal(count.length);
    }
  }, [totalMedia, type]);

  const handleChange = (value) => {
    setMinValue((value - 1) * 8);
    setMaxValue(value * 8);
  };
  const handleTypeChange = (e) => {
    setType(e);
    setMinValue(0);
    setMaxValue(8);
  };
  const handleUpload = (item, name, index = '') => {
    const formData = new FormData();
    formData.append('files', item.file);
    console.log('FormData', formData, item);
    newMediaUpload(formData, item.onProgress)
      .then((res) => {
        setAssetImage(res[0]);
        item.onSuccess(res, item.file);
      })
      .catch((err) => console.log(err));
  };
  const onChange = (item) => {
    const { status } = item.file;
    if (status === 'done') {
      message.success(`${item.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${item.file.name} file upload failed.`);
    }
  };

  const onFinish = () => {
    console.log('assetImage', assetImage);
    let name = assetImage.name.split('.')[0] || '';
    let data = {
      data: {
        asset: assetImage.id,
        type: 'user',
        contenttype: 'user',
        name,
      },
    };
    newAssets(data)
      .then((res) => {
        message.success('Added Asets successfully!');
      })
      .catch((err) => {
        message.error('Plz try again!');
      });
    setMediamodel(false);
    setLoading(true);
    getAssets().then((res) => {
      setTotalMedia(res);
      let count = res.data.filter((data) => data.attributes.contenttype === type);
      const allUniqueType = getType(res);
      setTotalType(allUniqueType);
      setTotal(count.length);
      setLoading(false);
    });
  };
  return (
    <div className="assetpagecss">
      <div className="d-flex justify-content-between">
        <span style={{ fontSize: 28, lineHeight: '32px' }}>My Assets</span>
        <div className="d-flex gap-4 align-items-center">
          <InputGroup
            className="tabsSearchButton"
            placeholder="Search by Keywords"
            leftIcon="search"
            asyncControl={true}
            onChange={() => null}
            style={{ minWidth: 200, height: 30 }}
          />
          {totalType && (
            <Select
              className="projectsSelect"
              name="status"
              placeholder=" Content Type"
              defaultValue={'background'}
              onChange={(e) => {
                handleTypeChange(e);
              }}>
              {totalType.map((data, id) => (
                <Option key={id} value={data.attributes.contenttype}>
                  {data.attributes.contenttype}
                </Option>
              ))}
            </Select>
          )}
          <Button className="assetUploadButton" onClick={() => setMediamodel(true)}>
            <span style={{ whiteSpace: 'nowrap' }}>
              <UploadmediaIcon /> Upload media
            </span>
          </Button>
        </div>
      </div>

      {loading ? (
        <div style={{ height: '75vh' }} className="loading-container">
          <div className="loader" />
        </div>
      ) : (
        <React.Fragment>
          <Row className="assetpagerowone">
            <div id="projects" className="assetpagecardlength">
              {totalMedia &&
                totalMedia.data
                  .filter((data) => data.attributes.contenttype === type)
                  .slice(minValue, maxValue)
                  .map(
                    (item, index) =>
                      item?.attributes?.asset?.data &&
                      item?.attributes?.asset?.data.length > 0 && (
                        <Card
                          interactive={true}
                          elevation={Elevation.TWO}
                          className="assetcards"
                          key={index}>
                          <Popover2
                            Position={Position.LEFT_BOTTOM}
                            content={
                              <div className="assettabsPopover">
                                <p className="popoverTitle">
                                  {
                                    item?.attributes?.asset?.data[0]?.attributes
                                      ?.name
                                  }
                                </p>
                                <hr />

                                <div className="popoverSubtitle">
                                  <Link
                                    id="edit"
                                    className="text-success cursor-pointer me-3"
                                    to={{
                                      pathname: `/edit/course/${item.id}`,
                                    }}>
                                    <DownloadIcon />
                                  </Link>

                                  <span className="popoverSubtitleText">
                                    Download this asset
                                  </span>
                                </div>
                                <div className="popoverSubtitle">
                                  <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() =>
                                      // deleteAsset(item.id, item.attributes.type)
                                      console.log('Hello')
                                    }
                                    onCancel={() => console.log('cancelled')}
                                    okText="Yes"
                                    cancelText="No">
                                    <DeleteSvg />
                                  </Popconfirm>
                                  <span className="popoverSubtitleText">
                                    Delete this asset
                                  </span>
                                </div>
                              </div>
                            }
                            position={Position.RIGHT_BOTTTOM}>
                            <Button icon="more" className="projectcardMore"></Button>
                          </Popover2>

                          <img
                            className="assetcardImage "
                            alt=""
                            src={
                              appConfig.socketURL +
                              item?.attributes?.asset?.data[0]?.attributes?.url?.replace(
                                '/',
                                ''
                              )
                            } />
                          <span className="assetCardsTitle">
                            {item?.attributes?.asset?.data[0]?.attributes?.name}
                          </span>
                        </Card>
                      )
                  )}
            </div>
          </Row>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Pagination onChange={handleChange} defaultCurrent={1} defaultPageSize={8} total={total} />
          </div>
        </React.Fragment>
      )}

      <Dialog
        title="Upload new Media"
        icon="info-sign"
        enforceFocus={false}
        onClose={() => {
          setMediamodel(false);
        }}
        isOpen={mediaModel}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        usePortal={false}>
        <div className={Classes.DIALOG_BODY}>
          <div>
            <Upload
              {...uploadProps}
              maxCount={1}
              customRequest={(item) => handleUpload(item)}
              listType="picture-card"
              onChange={onChange}>
              {uploadbutton}
            </Upload>
            <Button onClick={onFinish}>Save</Button>
            <br />
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Tooltip content="This button is hooked up to close the dialog.">
              <Button
                onClick={() => {
                  setMediamodel(false);
                }}>
                Close
              </Button>
            </Tooltip>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Asset;
