import React from 'react';
import { EuiFlexGroup, EuiCard, EuiIcon } from '@elastic/eui';
import { Link } from 'react-router-dom';

const NavLinkCards = ({ page }) => {
    return (
        <EuiFlexGroup className="navLinkPanelCard">
            <div>
                <Link href="/">
                    {page === 'resource' ? (
                        <div>
                            <EuiCard
                                className="saved-resource-btn-active"
                                style={{ margin: '0 auto' }}
                                icon={<EuiIcon size="xxxl" type={`/resources.png`} />}
                                title={<b className="crdTitle-active">{`Saved Resources`}</b>}
                            />
                            <div className="arrow-view-saved-resource"></div>
                        </div>
                    ) : (
                        <EuiCard
                            className="saved-resource-btn"
                            style={{ margin: '0 auto' }}
                            icon={<EuiIcon size="xxxl" type={`/resources.png`} />}
                            title={<b className="crdTitle">{`Saved Resources`}</b>}
                        />
                    )}
                </Link>
            </div>
            <div>
                <Link href="/dashboard/my-curriculum">
                    {page === 'curriculum' ? (
                        <div>
                            <EuiCard
                                className="my-curriculum-btn-active"
                                style={{ margin: '0 auto' }}
                                icon={<EuiIcon size="xxxl" type={`/curriculm.png`} />}
                                title={<b className="crdTitle">{`My Curriculum`}</b>}
                            />
                            <div className="arrow-view-curriculum"></div>
                        </div>
                    ) : (
                        <EuiCard
                            className="my-curriculum-btn"
                            style={{ margin: '0 auto' }}
                            icon={<EuiIcon size="xxxl" type={`/curriculm.png`} />}
                            title={<b className="crdTitle">{`My Curriculum`}</b>}
                        />
                    )}
                </Link>
            </div>
            <div>
                <EuiCard
                    className="my-student-btn"
                    style={{ margin: '0 auto' }}
                    icon={<EuiIcon size="xxxl" type={`/students.png`} />}
                    title={<b className="crdTitle">{`My Students`}</b>}
                    onClick={() => window.alert('Card clicked')}
                />
            </div>
            <div>
                <EuiCard
                    //   className="panelcrdfour"
                    className="my-assessment-btn"
                    style={{ margin: '0 auto' }}
                    icon={<EuiIcon size="xxxl" type={`/assessments.png`} />}
                    title={<b className="crdTitle">{`My Assessments`}</b>}
                    onClick={() => window.alert('Card clicked')}
                />
            </div>
            <div>
                <EuiCard
                    //   className="panelcrdfive"
                    className="studio-project-btn"
                    style={{ margin: '0 auto' }}
                    icon={<EuiIcon size="xxxl" type={`/my_studio.png`} />}
                    title={<b className="crdTitle">{`My Studio Projects`}</b>}
                    onClick={() => window.alert('Card clicked')}
                />
            </div>
        </EuiFlexGroup>
    );
};

export default NavLinkCards;
